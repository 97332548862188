<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex pointer">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <span v-if="userAvatar !== ''" class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  :src="userAvatar"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{ userName }}</span>
              </div>
            </div>
          </template>
          <router-link to="/profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>Мой профиль</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <span class="dropdown-item" @click="logoutAction">
            <i class="ni ni-user-run"></i>
            <span>Выход</span>
          </span>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { api } from '@/plugins/axios';

import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      userName: 'getUserName',
      userAvatar: 'getUserAvatar',
      isAdmin: 'isAdmin'
    })
  },
  methods: {
    ...mapActions(['logout']),
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logoutAction() {
      let route = this.isAdmin ? 'admin/login' : 'login';
      await api.post('/logout').then(async () => {
        await this.logout();
        this.$router.push({ name: route }).catch(() => {});
        localStorage.clear();
      });
    }
  },
};
</script>
