import { api } from '@/plugins/axios';

const state = {
    photographer: null,
};

const getters = {
    getPhotographer: state => state.photographer,
};

const actions = {
    async fetchPhotographer({ commit }, photographerId ) {
        try {
            const { data } = await api.get(`admin/photographers/${photographerId}`);
            commit('setItem', { key: 'photographer', value: data });
        } catch (e) {
            throw (e);
        }
    },

    fetchItemSecret({ commit }, { key, value } ) {
        let item = state[key];
        item.secret = value;

        commit('setItem', { key, value: item });
    }
};

const mutations = {
    setItem(state, { key, value }) {
        state[key] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
