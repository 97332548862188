<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow p-3">
            <span class="alert alert-info">
              Если к галерее или к группе был привязан водяной знак, то он появится со временем.
              Вставка водяных знаков происходит в очереди (процесс в фоне), чтобы вы не ждали долгой обработки.
            </span>
            <drop-zone :get-url="url"
                       :delete-url="url"
                       :upload-url="url"
                       :allow-message="allowMessage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";

import DropZone from "@/components/DropZone";
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'photographers/groups/galleries/images',
  components: { DropZone },
  computed: {
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
    child() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.child_item)}`];
    },
  },
  data() {
    return {
      allow: false,
      allowMessage: '',
      url: `${api.defaults.baseURL}photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/images`,
    };
  },
  async created() {
    let { data: { allow_date, allow_count, allow_group } } = await api.get(`photographers/groups/${this.$route.params.itemId}/allow`);
    if (!allow_date && !(allow_count || allow_group)) {
      this.allowMessage = 'Ваш пакет закончился, вы не можете произвести данное действие.';
    } else if (!allow_count && !(allow_date || allow_group)) {
      this.allowMessage = 'Ваш пакет закончился, вы не можете произвести данное действие.';
    } else if (!allow_group && !allow_date) {
      this.allowMessage = 'Ваш пакет не позволяет производить данное действие спустя 2 дня после создания галереи.';
    }

    await this.fetchPhotographerItemChild({
      itemId: this.$route.params.itemId,
      childId: this.$route.params.childId,
      type: this.schema.type,
      childType: this.schema.child_type,
      key: this.schema.item,
      childKey: this.schema.child_item,
    });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item });
    this.setAppItem({ key: 'secondaryBreadcrumb', value: this.child });
  },
  methods: {
    ...mapActions('photographer', ['fetchPhotographerItemChild']),
    ...mapActions(['setAppItem']),
  }
};
</script>
