<template>
  <additional-block v-if="additional" :is-loading="loading" :show-footer="!disabled">
    <template v-slot:title>
      <div class="display-flex justify-content-between">
        <span>Пользователи</span>
        <base-button @click="$parent.showUsers = false;" type="primary" size="sm">Закрыть</base-button>
      </div>
    </template>
    <template v-slot:data>
      <div v-for="user in users" class="mb-3 row pb-3 border-bottom-1-black">
        <div class="col-lg-12 row">
          <div class="col-lg-4 pb-2">
            <img v-if="user.photo"
                 style="max-width: 100%; max-height: 100%;"
                 :src="user.photo.conversions[0].url"
                 :alt="user.name"
            />
          </div>
          <div class="col-lg-8 pb-2">
            <label class="form-control-label">Галерея:&nbsp;</label>
            <br />
            {{ user.name }}
          </div>
        </div>
        <div class="col-lg-12 row mb-2 mt-2">
          <div v-if="!!user.user" class="col-lg-12 border-bottom-1-gray border-top-1-gray pt-2 pb-2 mb-2">
            <label class="form-control-label">ФИО пользователя:&nbsp;</label>
            <br />
            {{ user.user.name }}
          </div>
          <div v-if="!!user.user" class="col-lg-6 border-bottom-1-gray pb-2">
            <label class="form-control-label">Логин:&nbsp;</label>
            <br />
            {{ user.user.login }}
          </div>
          <div v-if="!!user.user" class="col-lg-6 border-bottom-1-gray pb-2">
            <label class="form-control-label">Пароль:&nbsp;</label>
            <br />
            {{ user.user.secret }}
          </div>
        </div>
        <div class="col-lg-12 mt-1" v-if="!!user.user && action === 'edit'">
          <ban :user="user.user" />
          <delete :user-id="user.user.id" v-on:clear-user="getUsers" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <base-button v-if="action === 'edit'" type="success" @click="generateAllUsersModal = true">Сгенерировать пользователей для галерей</base-button>
      <br v-if="action === 'edit'" />
      <br v-if="action === 'edit'" />
      <base-button v-if="action === 'edit'" type="danger" @click="removeAllUsersModal = true">Удалить всех пользователей</base-button>
    </template>
  </additional-block>

  <action-modal title="Удаление"
                text="Вы действительно хотите удалить всех пользователей данной группы?"
                action-text="Удалить"
                :show-modal="removeAllUsersModal"
                item="removeAllUsersModal"
                v-on:action="deleteUsers"
                :is-loading="modalLoading"
  />

  <action-modal title="Генерация пользователей"
                text="Вы действительно хотите сгенерировать пользователей для всех галерей данной группы где они отсутствуют?"
                action-text="Сгенерировать"
                :show-modal="generateAllUsersModal"
                item="deleteModal"
                v-on:action="generateUsers"
                :is-loading="modalLoading"
  />
</template>

<script>
import ActionModal from "@/views/modals/action-modal";
import AdditionalBlock from "@/views/components/additional-block";
import Ban from '@/views/components/user/ban';
import Delete from '@/views/components/user/delete';

import { api } from "@/plugins/axios";
import BaseButton from "@/components/BaseButton";

export default {
  name: 'additional/users',

  components: { BaseButton, ActionModal, AdditionalBlock, Ban, Delete },

  props: {
    action: {
      type: String,
      default: '',
    },

    additional: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      removeAllUsersModal: false,
      generateAllUsersModal: false,
      loading: false,
      modalLoading: false,
      users: [],
    };
  },

  methods: {
    async getUsers() {
      this.loading = true;

      try {
        let { data } = await api.get(`photographers/groups/${this.$route.params.itemId}/gallery/users`);

        this.users = data;
      } catch(e) {
        this.$parent.showUsers = false;
        throw e;
      } finally {
        this.loading = false;
      }
    },

    async deleteUsers() {
      this.modalLoading = true;
      try {
        await api.delete(`photographers/groups/${this.$route.params.itemId}/remove-users`);
      } finally {
        this.removeAllUsersModal = false;
        this.modalLoading = false;

        await this.getUsers();
      }
    },

    async generateUsers() {
      this.modalLoading = true;
      try {
        await api.post(`photographers/groups/${this.$route.params.itemId}/generate-users`);
      } finally {
        this.generateAllUsersModal = false;
        this.modalLoading = false;

        await this.getUsers();
      }
    },

    async toggleBan() {
      try {
        this.loading = true;

        await api.post(`photographers/groups/${this.$route.params.itemId}/users/${this.user.id}/access/toggle`);
        this.user.status = this.user.status === 'enabled' ? 'disabled' : 'enabled';
        this.user.status_name = this.statuses.filter(i => i.value === this.user.status)[0].text;

        this.$emit('refresh-table');
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
