<template>
  <modal v-model:show="showModal">
    <template v-slot:header>
      <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
    </template>
    <slot name="text"></slot>
    <template v-slot:footer>
      <base-button type="secondary" @click="showModal = false; action();">Закрыть</base-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: 'info-modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['action'],
  methods: {
    action() {
      this.$emit('action');
    }
  }
};
</script>
