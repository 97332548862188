import GlobalComponents from '@/components';
import GlobalDirectives from '@/directives';

import SidebarPlugin from "@/components/SidebarPlugin";

import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/scss/all.scss";

export default {
    install(app) {
        app.use(SidebarPlugin);
        app.use(GlobalComponents);
        app.use(GlobalDirectives);
    },
};
