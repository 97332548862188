<template>
  <base-input form-classes="media-body mr-2" alternative placeholder="Поиск..." @keyup="search" :value="value" />
</template>

<script>
import { useRouter, useRoute } from "vue-router";

export default {
  name: 'Base table search',

  data() {
    return {
      timeout: null,
      router: useRouter(),
      route: useRoute(),
      value: null
    };
  },

  mounted() {
    let search = this.route.query.search;
    if (!!search) {
      this.value = search;
      this.$emit('search', search);
    }
  },

  methods: {
    search(e) {
      let self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        self.$emit('search', e.target.value || null );
        const query = { ...self.route.query, search: e.target.value || undefined };
        self.router.replace( { query } );
      }, 500);
    }
  }
};
</script>
