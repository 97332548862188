<template>
  <additional-block v-if="additional" :is-loading="loading" :show-footer="!disabled">
    <template v-slot:title>
      <div class="display-flex justify-content-between">
        <span>Связаные галереи</span>
        <base-button @click="$parent.showRelations = false;" type="primary" size="sm">Закрыть</base-button>
      </div>
    </template>
    <template v-slot:data>
      <base-select
          alternative
          label=""
          @change="addSelected"
          :options="galleries.map(i => {
            return {
              text: i.name,
              value: i.id,
            };
          })"
          v-model="selectedItem"
          v-if="!disabled"
      >
        <template v-slot:infoBlock>
          <div class="col-lg-12 hint">Связать можно только галереи одной группы</div>
        </template>
      </base-select>
      <div v-if="selected.length">
        <div class="alert alert-default position-relative" v-for="item in selected">
          <i v-if="!disabled" class="fa fa-trash position-absolute top-15 right-15 pointer" @click="detachItem(item)" />
          {{ item.name }}
        </div>
      </div>
    </template>
    <template v-if="!disabled" v-slot:footer>
      <div class="btn btn-success" @click="save()">Сохранить</div>
    </template>
  </additional-block>
</template>

<script>
import { api } from "@/plugins/axios";

import AdditionalBlock from "@/views/components/additional-block";

export default {
  name: 'additional/relations',

  components: { AdditionalBlock },

  props: {
    additional: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      relations: [],
      galleries: [],
      selected: [],
      selectedItem: null,
    };
  },

  unmount() {
    this.relations = [];
    this.galleries = [];
    this.selected = [];
  },

  async created() {
    await this.getData();
  },

  methods: {
    addSelected(e) {
      let item = this.galleries.filter(i => i.id === parseInt(this.selectedItem));
      if (item) {
        item = item[0];
        this.selected.push(item);
        let index = this.galleries.indexOf(item);
        if (index !== -1) {
          this.galleries.splice(index, 1);
        }
      }

      this.selectedItem = null;
    },

    detachItem(item) {
      let index = this.selected.indexOf(item);
      if (index !== -1) {
        this.selected.splice(index, 1);
        this.galleries.push(item);
      }
    },

    async getData() {
      this. loading = true;

      try {
        await this.getGalleries();
        await this.getRelations();

        this.selected = this.galleries.filter(i => this.relations.includes(i.id));
        this.galleries = this.galleries.filter(i => !this.relations.includes(i.id));
      } finally {
        this.loading = false;
      }
    },

    async getRelations() {
      let { data } = await api.get(`photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/relations`);

      this.relations = data;
    },

    async getGalleries() {
      let { data } = await api.get(`photographers/groups/${this.$route.params.itemId}/short-galleries/${this.$route.params.childId}`);

      this.galleries = data;
    },

    async save() {
      this.loading = true;
      try {
        let data = {};
        if (this.selected.length) {
          data.relations = this.selected.map(i => i.id);
        }

        await api.post(`photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/relations`, data);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
