export default [
    {
        icon: 'fa fa-image',
        action: 'image',
        title: 'Изображение',
        method: 'goToItem',
        condition: "item.type === 'many'",
    },
    ...require('./delete').default,
];
