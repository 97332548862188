import { api } from '@/plugins/axios';

const state = {
    photographer: null,
    book: null,
    calendar: null,
    size: null,
    magnet: null,
    watermark: null,
    order: null,
    discount: null,
    processing: null,
    group: null,
    gallery: null,
    avatar: null,
};

const getters = {
    getPhotographer: state => state.photographer,
    getBook: state => state.book,
    getCalendar: state => state.calendar,
    getSize: state => state.size,
    getMagnet: state => state.magnet,
    getWatermark: state => state.watermark,
    getOrder: state => state.order,
    getDiscount: state => state.discount,
    getProcessing: state => state.processing,
    getGroup: state => state.group,
    getGallery: state => state.gallery,
    getAvatar: state => state.avatar,
};

const actions = {
    async fetchPhotographerItem({commit}, {itemId, type, key}) {
        try {
            const {data} = await api.get(`photographers/${type}/${itemId}`);
            commit('setItem', {key, value: data});
        } catch (e) {
            throw (e);
        }
    },

    async fetchPhotographerItemChild({dispatch, commit}, {itemId, childId, type, key, childType, childKey}) {
        await dispatch('fetchPhotographerItem', {itemId, type, key});
        try {
            const {data} = await api.get(`photographers/${type}/${itemId}/${childType}/${childId}`);
            commit('setItem', {key: childKey, value: data});
        } catch (e) {
            throw (e);
        }
    },

    async fetchPhotographerItemAvatar({ commit, dispatch }, { url }) {
        await dispatch('setAppItem', { key: 'isLoading', value: true }, { root: true });
        try {
            await api.get(url).then(({ data }) => {
                commit('setItem', { key: 'avatar', value: data.length === 0 ? null : data });
            });
        } catch (e) {
            throw e;
        } finally {
            await dispatch('setAppItem', { key: 'isLoading', value: false }, { root: true });
        }
    },

    async setPhotographerItemAvatar({ commit, dispatch }, { url, e }) {
        if (e.target.files.length === 0) {
            return;
        }

        await dispatch('setAppItem', { key: 'isLoading', value: true }, { root: true });
        try {
            let data = new FormData();
                data.append('image', e.target.files[0]);

            await api.post(url, data).then(async () => {
                await dispatch('fetchPhotographerItemAvatar', { url });
            });
        } finally {
            e.target.value = null;
            await dispatch('setAppItem', { key: 'isLoading', value: false }, { root: true });
        }
    },

    async removePhotographerItemAvatar({ commit, dispatch }, { url, e }) {
        await dispatch('setAppItem', { key: 'isLoading', value: true }, { root: true });
        try {
            await api.delete(url).then(async () => {
                await dispatch('fetchPhotographerItemAvatar', { url });
            });
        } catch (e) {
            throw e;
        } finally {
            await dispatch('setAppItem', { key: 'isLoading', value: false }, { root: true });
        }
    },
}

const mutations = {
    setItem(state, { key, value }) {
        state[key] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
