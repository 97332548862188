const items = ['book', 'calendar', 'size', 'magnet', 'watermark', 'discount', 'processing', 'group', 'order'];

export default [
    ...items.map(i => {
        return {
            type: `${i}s`,
            item: i,
            routes: ['', '/show', '/edit', '/store'].map(j => {
                return `photographers/${i}s${j}`;
            }),
        };
    }),
    {
        type: 'groups',
        item: 'group',
        child_type: 'galleries',
        child_item: 'gallery',
        routes: ['', '/show', '/edit', '/store', '/images'].map(j => {
            return `photographers/groups/galleries${j}`;
        }),
    },
    {
        type: 'groups',
        item: 'group',
        child_type: 'users',
        child_item: 'user',
        routes: [''].map(j => {
            return `photographers/groups/users${j}`;
        }),
    },
]
