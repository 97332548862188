<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="!!photographer" class="card shadow">
            <div class="card-body">
              <div v-for="field in fields">
                <base-input v-if="field.type === 'text'" alternative :label="field.title" v-model="photographer[field.name]"></base-input>
                <base-select v-if="field.type === 'select'" alternative :label="field.title" :options="this[field.options]" v-model="photographer[field.name]"></base-select>
              </div>
              <div class="display-flex justify-content-end">
                <base-button type="danger" @click="resetPasswordModal = true">Сброс пароля</base-button>
                <base-button type="success" @click="sendForm()">Редактировать</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <action-modal title="Сброс пароля"
                  text="Вы действительно хотите сбросить пароль данного фотографа?"
                  action-text="Сбросить"
                  :show-modal="resetPasswordModal"
                  item="resetPasswordModal"
                  v-on:action="resetPassword"
    />

    <info-modal title="Новый пароль"
                :show-modal="passwordResetModal"
                v-on:action="newPassword = null; passwordResetModal = false"
    >
      <template v-slot:text>
        <div v-if="!!photographer">
          Пароль для фотографа: {{ photographer.name }} сброшен. Новый пароль: {{ newPassword }}
        </div>
      </template>
    </info-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ActionModal from '@/views/modals/action-modal';
import InfoModal from '@/views/modals/info-modal';

import { api } from '@/plugins/axios';

export default {
  name: 'admin/photographers/edit',
  components: { ActionModal, InfoModal },
  computed: {
    ...mapGetters({
      photographer: 'adminPhotographer/getPhotographer'
    }),
  },
  data() {
    return {
      fields: require('@/columns/users').default,
      resetPasswordModal: false,
      passwordResetModal: false,
      newPassword: null,
      statuses: [{ text: 'Активный', value: 'enabled' }, { text: 'Выключен', value: 'disabled' }],
    };
  },
  async created() {
    await this.fetchPhotographer(this.$route.params.photographerId);

    this.setAppItem({ key: 'mainBreadcrumb', value: this.photographer });
  },
  methods: {
    ...mapActions('adminPhotographer', ['fetchPhotographer', 'fetchItemSecret']),
    ...mapActions(['setAppItem']),
    async resetPassword() {
      this.isLoading = true;
      try {
        await api.post(`admin/photographers/${this.$route.params.photographerId}/reset-password`).then(({ data }) => {
          this.isLoading = false;
          this.resetPasswordModal = false;
          this.newPassword = data.secret;
          this.passwordResetModal = true;

          this.fetchItemSecret({ key: 'photographer', value: data.secret });
        });
      } catch (e) {
        this.isLoading = false;
        this.resetPasswordModal = false;
        throw e;
      }
    },
    async sendForm() {
      this.isLoading = true;
      try {
        await api.put(`admin/photographers/${this.$route.params.photographerId}`, this.photographer).then(() => {
          this.$router.push({ name: 'admin/photographers' });
        });
      } catch (e) {
        this.isLoading = false;
        throw e;
      }
    },
  }
}
</script>
