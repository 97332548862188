<template>
  <div class="btn btn-show" :class="{ hidden: show }" @click="showForm()">Вход</div>
  <div class="auth-container" :class="{ show: show }">
    <div class="top"></div>
    <div class="bottom"></div>
    <div class="login-form">
      <h1>Авторизация</h1>
      <div v-if="$route.name !== 'admin/login'">
        <input v-model="login" @keypress="onlyNumberWithLength" name="login" @input="checkInputs" type="text" placeholder="Логин" />
        <input v-model="password" @keypress="onlyNumberWithLength" name="password" @input="checkInputs" type="password" placeholder="Пароль" />
      </div>
      <div v-else>
        <input v-model="login" type="email" placeholder="Логин" />
        <input v-model="password" type="password" placeholder="Пароль" />
      </div>
      <div :class="{ disabled: !showBtn }" class="btn btn-login" @click="auth()">Войти</div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Auth",

  data() {
    return {
      login: null,
      password: null,
      showBtn: false,
      loginLength: 8,
      passwordLength: 6,
      show: this.$route.name === 'admin/login'
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin',
      isGroup: 'isGroup',
      isUser: 'isUser',
      isPhotographer: 'isPhotographer',
    })
  },

  created() {
    if (this.$route.name === 'admin/login') {
      this.showBtn = true;
    }
  },

  methods: {
    ...mapActions(['loginWithoutToken', 'setAppItem']),

    onlyNumberWithLength(e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which);
      if (keyCode < 48 || keyCode > 57) {
        e.preventDefault();
      }

      let isLogin = e.target.name === 'login';
      this.checkInput( e, isLogin ? this.loginLength : this.passwordLength);
    },

    checkInputs(e) {
      this.showBtn = this.login !== null && this.password !== null
                  && this.login.length === this.loginLength && this.password.length === this.passwordLength;
    },

    checkInput(e, length) {
      if (e.target.value.length >= length) {
        e.preventDefault();
      }
    },

    showForm() {
      this.show = true;
    },

    getRoute() {
      if (this.isAdmin) {
        return 'admin';
      } else if (this.isPhotographer) {
        return 'photographers';
      } else if (this.isGroup) {
        return 'groups';
      } else {
        return 'users';
      }
    },

    async auth() {
      if (!this.showBtn) {
        return;
      }

      this.setAppItem({ key: 'isLoading', value: true });

      try {
        let isLogin = await this.loginWithoutToken({ login: this.login, password: this.password });

        if (isLogin) {
          this.$router
              .push({ name: this.getRoute() })
              .catch(e => {
              });
        }
      } catch (e) {
        this.password = null;

        throw e;
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  margin-top: 15px;
  padding: 10px 15px;
  background: #000;
  color: #fff;
  font-weight: bold;
  width: 200px;
  cursor: pointer;
}

.btn-show {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  &:hover {
    transform: translate(-50%, -50%) !important;
  }
}

.top, .bottom {
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: 200vmax;
    height: 200vmax;
    top: 50%;
    left: 50%;
    margin-top: -100vmax;
    transform-origin: 0 50%;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0, 1);
    z-index: 10;
    opacity: 0.65;
    transition-delay: 0.2s;
  }
}

.top {
  &:before { transform: rotate(45deg); background: #e46569; }
  &:after { transform: rotate(135deg); background: #ecaf81; }
}

.bottom{
  &:before { transform: rotate(-45deg); background: #60b8d4; }
  &:after { transform: rotate(-135deg); background: #3745b5; }
}

.login-form {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  color: #333;
  opacity: 0;

  input {
    width: 100%;
    padding: 15px;
    margin: 5px;
    border-radius: 1px;
    border: 1px solid #ccc;
    font-family: inherit;
    outline: none;
    background: #fff !important;
  }

  .btn-login {
    &.disabled {
      cursor: default;
      background: #c4c4c4;
    }
  }
}

.auth-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.show {
    .top, .bottom {
      &:before, &:after {
        margin-left: 200px;
        transform-origin: -200px 50%;
        transition-delay: 0s;
      }
    }

    .login-form {
      opacity: 1;
      transition-delay: 0.2s;
    }
  }
}

.hidden {
  display: none;
}
</style>
