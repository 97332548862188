<template>
  <component :is="form"
             :item="item"
             :child="child"
             :disabled="true"
             :type="schema.type"
             :child-type="schema.child_type"
             action="show"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'photographers/custom/show',
  computed: {
    ...mapGetters({
      id: 'getUserId',
    }),
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
    child() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.child_item)}`];
    },
    form() {
      return require(`@/views/components/forms/${this.schema.type}/${this.schema.child_type}`).default;
    }
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
    this.setAppItem({ key: 'secondaryBreadcrumb', value: null });
  },
  async created() {
    await this.fetchPhotographerItemChild({
      itemId: this.$route.params.itemId,
      childId: this.$route.params.childId,
      type: this.schema.type,
      childType: this.schema.child_type,
      key: this.schema.item,
      childKey: this.schema.child_item,
    });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item });
    this.setAppItem({ key: 'secondaryBreadcrumb', value: this.child });
  },
  methods: {
    ...mapActions('photographer', ['fetchPhotographerItem', 'fetchPhotographerItemChild']),
    ...mapActions(['setAppItem']),
  }
};
</script>
