<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="!!model" class="card shadow mh-500">
            <loader v-if="loading" />
            <div class="card-body">
              <base-input :disabled="disabled" :error="errors.name ? errors.name[0] : null" alternative label="Наименование" v-model="model.name" />
              <base-input :disabled="disabled" :error="errors.size ? errors.size[0] : null" alternative label="Размер" v-model="model.size" />
              <base-input :disabled="disabled" :error="errors.price ? errors.price[0] : null" alternative label="Цена" v-model="model.price" />
              <base-select :disabled="disabled" :error="errors.type ? errors.type[0] : null" alternative label="Тип" :options="types" v-model="model.type" />
              <base-select :disabled="disabled" :error="errors.orientation ? errors.orientation[0] : null" v-if="model.type === 'single'" alternative label="Ориентация" :options="orientations" v-model="model.orientation" />
              <base-input :disabled="disabled" v-if="model.type === 'many'" :error="errors.count ? errors.count[0] : null" alternative label="Кол-во фото" v-model="model.count" />
              <div v-if="action !== 'show'" class="display-flex justify-content-end">
                <base-button type="success" @click="sendForm()">{{ buttonText }}</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";
import { mapActions, mapGetters } from "vuex";
import loader from '@/components/Loading';

export default {
  name: 'forms/calendars',
  components: { loader },
  props: {
    item: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    redirectRoute: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Редактировать',
    },
    method: {
      type: String,
      default: 'put',
    },
    action: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({ loading: 'getIsLoading', errors: 'getErrors' }),
  },
  data() {
    return {
      model: null,
      types: [{ text: 'Одна картинка', value: 'single' }, { text: 'Несколько картинок', value: 'many' }],
      orientations: [{ text: 'Горизонтальная', value: 'horizontal' }, { text: 'Вертикальная', value: 'vertical' }],
    };
  },
  created() {
    this.model = this.item;
  },
  unmounted() {
    this.setAppItem({ key: 'errors', value: {} });
  },
  methods: {
    ...mapActions(['setAppItem']),
    async sendForm() {
      this.setAppItem( { key: 'isLoading', value: true });
      try {
        let url = `${this.url}${this.item.id ? ('/' + this.item.id) : ''}`;
        await api[this.method](url, this.model).then(({ data }) => {
          let id = null;
          if (data.id) {
            id = this.item.id;
          } else {
            id = data.id;
          }
          let params = this.$route.params;
          params.itemId = id;
          this.$router.push({ name: this.redirectRoute, params });
        });
      } catch (e) {
        throw e;
      } finally {
        this.setAppItem( { key: 'isLoading', value: false });
      }
    },
  },
  watch: {
    item(data) {
      this.model = data;
    }
  }
};
</script>
