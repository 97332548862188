import Dashboard from "@/views/admin/dashboard";

import Photographers from "@/views/admin/photographers";
import showPhotographer from "@/views/admin/photographer/show";
import editPhotographer from "@/views/admin/photographer/edit";

import Users from "@/views/admin/users";
import showUser from "@/views/admin/user/show";
import editUser from "@/views/admin/user/edit";

import Packages from "@/views/admin/packages";
import showPackage from "@/views/admin/package/show";
import editPackage from "@/views/admin/package/edit";
import storePackage from "@/views/admin/package/store";

import { guardsSequence, includesRole, isAuthenticated } from "@/routes/guards";

const check = guardsSequence(isAuthenticated, includesRole(['isAdmin']));


export default [
    {
        path: '/admin',
        name: 'admin',
        components: { default: Dashboard },
        title: 'Главная',
        beforeEnter: check,
    },
    {
        path: '/admin/photographers',
        name: 'admin/photographers',
        components: { default: Photographers },
        title: 'Фотографы',
        links: ['admin'],
        beforeEnter: check,
        breadcrumb_type: 'main',
    },
    {
        path: '/admin/photographers/:photographerId/show',
        name: 'admin/photographers/show',
        components: { default: showPhotographer },
        title: 'Просмотр',
        links: ['admin', 'admin/photographers'],
        beforeEnter: check,
    },
    {
        path: '/admin/photographers/:photographerId/edit',
        name: 'admin/photographers/edit',
        components: { default: editPhotographer },
        title: 'Редактирование',
        links: ['admin', 'admin/photographers'],
        beforeEnter: check,
    },
    {
        path: '/admin/users',
        name: 'admin/users',
        components: { default: Users },
        title: 'Пользователи',
        links: ['admin'],
        beforeEnter: check,
        breadcrumb_type: 'main',
    },
    {
        path: '/admin/users/:userId/show',
        name: 'admin/users/show',
        components: { default: showUser },
        title: 'Просмотр',
        links: ['admin', 'admin/users'],
        beforeEnter: check,
    },
    {
        path: '/admin/users/:userId/edit',
        name: `admin/users/edit`,
        components: { default: editUser },
        title: 'Редактирование',
        links: ['admin', 'admin/users'],
        beforeEnter: check,
    },
    {
        path: '/admin/packages',
        name: 'admin/packages',
        components: { default: Packages },
        title: 'Пакеты',
        links: ['admin'],
        beforeEnter: check,
        breadcrumb_type: 'main',
    },
    {
        path: '/admin/packages/:packageId/show',
        name: 'admin/packages/show',
        components: { default: showPackage },
        title: 'Просмотр',
        links: ['admin', 'admin/packages'],
        beforeEnter: check,
    },
    {
        path: '/admin/packages/:packageId/edit',
        name: `admin/packages/edit`,
        components: { default: editPackage },
        title: 'Редактирование',
        links: ['admin', 'admin/packages'],
        beforeEnter: check,
    },
    {
        path: '/admin/packages/store',
        name: `admin/packages/store`,
        components: { default: storePackage },
        title: 'Создание',
        links: ['admin', 'admin/packages'],
        beforeEnter: check,
    },
];
