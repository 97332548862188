<template>
  <items-form :url="`photographers/${this.schema.type}`"
        :item="item"
        :type="schema.type"
        :fields="fields"
        :disabled="true"
        action="show"
  />
</template>

<script>
import ItemsForm from '@/views/components/forms/items';

import { mapGetters, mapActions } from "vuex";

export default {
  name: 'photographers/items/show',
  components: { ItemsForm },
  computed: {
    ...mapGetters({ id: 'getUserId' }),
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    fields() {
      return require(`@/columns/${this.schema.type}`).default.filter(i => (i.form === undefined && i.show === undefined) || i.show);
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
  },
  async created() {
    await this.fetchPhotographerItem({ itemId: this.$route.params.itemId, type: this.schema.type, key: this.schema.item });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item });
  },
  methods: {
    ...mapActions('photographer', ['fetchPhotographerItem']),
    ...mapActions(['setAppItem']),
  }
};
</script>
