<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="transition-all" :class="{ 'col-lg-12': getFull(), 'col-lg-8': getShort() }">
          <div class="card shadow mh-500">
            <loader v-if="loading" />
            <div v-if="!!model" class="card-body">
              <base-input :disabled="disabled" :error="errors.name ? errors.name[0] : null" alternative label="Наименование" v-model="model.name" />
              <base-input :disabled="disabled" :error="errors.url ? errors.url[0] : null" alternative label="URL" v-model="model.url" />
              <base-input v-if="disabled" alternative disabled label="Статус" v-model="model.status_name"></base-input>
              <base-select v-if="!disabled" :error="errors.status ? errors.status[0] : null" alternative label="Статус" :options="statuses" v-model="model.status"></base-select>
              <base-button v-if="action !== 'store' && !!user" @click="open('showUser')">Показать пользователя</base-button>
              <base-button v-if="action === 'edit' && !user" type="success" @click="generateUserModal = true">Сгенерировать пользователя</base-button>
              <br v-if="action !== 'store'" />
              <br v-if="action !== 'store'" />
              <base-button v-if="action !== 'store'" type="success" @click="open('showRelations')">Связаные галереи</base-button>
              <br v-if="action !== 'store'" />
              <br v-if="action !== 'store'" />
              <image-component ref="avatar" v-if="action !== 'store'"
                     :show-input="!disabled"
                     :load-image="this.action !== 'store'"
                     :url="imageUrl"
              />
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="display-flex flex-wrap col-lg-6 col-xs-12 z-index-1 position-relative justify-content-start"
                     v-if="action !== 'store'"
                >
                  <div class="btn btn-secondary btn-sm display-flex align-items-center mb-1"
                       v-for="relation in relations"
                       @click="open('additional'); $refs['items'].showRelationBlock(relation)"
                  >
                    {{ relation.name }}
                  </div>
                </div>
                <div v-if="action !== 'show'"
                     class="display-flex justify-content-end col-xs-12 position-relative"
                     :class="{ 'col-lg-6': action !== 'store', 'col-lg-12': action === 'store' }"
                >
                  <base-button v-if="action === 'edit'" type="danger" @click="deleteModal = true">
                    Удалить все фотографии
                  </base-button>
                  <base-button type="success" @click="sendForm()">{{ buttonText }}</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <action-modal title="Удаление"
                      text="Вы действительно хотите удалить все фотографии данной галереи?"
                      action-text="Удалить"
                      :show-modal="deleteModal"
                      item="deleteModal"
                      v-on:action="deletePhotos"
                      :is-loading="modalLoading"
        />

        <action-modal title="Генерация пользователя"
                      text="Вы действительно хотите сгенерировать пользователя для данной галереи?"
                      action-text="Сгенерировать"
                      :show-modal="generateUserModal"
                      item="generateUserModal"
                      v-on:action="generateUser"
                      :is-loading="modalLoading"
        />

        <user :user="user" :additional="showUser" :disabled="action === 'show'">
          <template v-slot:additional_actions>
            <delete :user-id="user.id" v-on:clear-user="clearUser()" />
          </template>
        </user>
        <items :additional="additional"
               ref="items"
               :disabled="disabled" :url="`photographers/groups/${$route.params.itemId}/galleries/${$route.params.childId}`"
        />
        <relations v-if="action !== 'store'" :additional="showRelations" :disabled="action === 'show'" />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";
import { mapActions, mapGetters } from "vuex";

import loader from "@/components/Loading";
import ImageComponent from "@/views/components/forms/image";
import ActionModal from "@/views/modals/action-modal";
import User from '@/views/photographer/additional/user';
import Items from '@/views/photographer/additional/items';
import Relations from '@/views/photographer/additional/relations';
import Delete from '@/views/components/user/delete';

export default {
  name: 'forms/groups',

  components: { loader, ImageComponent, ActionModal, User, Items, Relations, Delete },

  props: {
    item: {
      type: Object,
      default: null,
    },
    child: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    redirectRoute: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Редактировать',
    },
    method: {
      type: String,
      default: 'put',
    },
    type: {
      type: String,
      default: '',
    },
    childType: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters({ loading: 'getIsLoading', errors: 'getErrors' }),

    imageUrl() {
      return `photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/image`;
    },
  },

  data() {
    return {
      statuses: [{ text: 'Активна', value: 'enabled' }, { text: 'Скрыта', value: 'disabled' }],
      model: null,
      relations: require(`@/types/photographer/groups`).default,
      additional: false,
      user: null,
      deleteModal: false,
      modalLoading: false,
      showUser: false,
      generateUserModal: false,
      showRelations: false,
    };
  },

  async created() {
    this.parent = this.item;
    this.model = this.child;

    if (this.action !== 'store') {
      await this.getUser();
    }
  },

  unmounted() {
    this.setAppItem({ key: 'errors', value: {} });
  },

  methods: {
    ...mapActions(['setAppItem']),

    clearUser() {
      this.user = null;
      this.showUser = false;
    },

    async deletePhotos() {
      this.modalLoading = true;
      await api.delete(`photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/remove-photos`).then(async () => {
        await this.$refs.avatar.fetchPhotographerItemAvatar({ url: this.imageUrl });
        this.deleteModal = false;
        this.modalLoading = false;
      });
    },

    async getUser() {
      let { data } = await api.get(`photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/user`);

      this.user = data.empty ? null : data;
    },

    async sendForm() {
      this.setAppItem({ key: 'isLoading', value: true });
      try {
        let url = `${this.url}${this.child.id ? ('/' + this.child.id) : ''}`;
        await api[this.method](url, this.model).then(({ data }) => {
          let id = null;
          if (data.id) {
            id = this.child.id;
          } else {
            id = data.id;
          }
          let params = this.$route.params;
              params.childId = id;
          this.$router.push({ name: this.redirectRoute, params });
        });
      } catch (e) {
        throw e;
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }
    },

    open(item) {
      ['additional', 'showRelations', 'showUser'].map(i => this[i] = item === i);
    },

    async generateUser() {
      try {
        this.modalLoading = true;
        this.setAppItem({ key: 'isLoading', value: true });

        let { data } = await api.post(`photographers/groups/${this.$route.params.itemId}/galleries/${this.$route.params.childId}/user/generate`);

        this.user = data;
        this.open('showUser');
        this.generateUserModal = false;
      } catch (e) {
        throw e;
      } finally {
        this.modalLoading = false;
        this.setAppItem({ key: 'isLoading', value: false });
      }
    },

    getFull() {
      return !this.additional && !this.showUser && !this.showRelations;
    },

    getShort() {
      return this.additional || this.showUser || this.showRelations;
    },
  },
  watch: {
    item(data) {
      this.parent = data;
    },
    child(data) {
      this.model = data;
    }
  }
};
</script>
