export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
        form: false,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'price',
        title: 'Цена',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'count',
        title: 'Порог',
        sortable: true,
        table: true,
        type: 'text',
        hint: 'Кол-во фото стоимость за которые входит в стоимость обработки',
    },
    {
        name: 'after_limit_count',
        title: 'Кол-во после порога',
        table: true,
        type: 'text',
        hint: 'Кол-во фото стоимость за которые не входит в стоимость обработки',
    },
    {
        name: 'after_limit_price',
        title: 'Цена за кол-во после порога',
        table: true,
        type: 'text',
        hint: 'Цена за каждое кол-во после порога',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        form: false,
        table: true,
    },
    {
        name: 'updated_at',
        title: 'Дата последнего обновления',
        form: false,
        table: true,
    },
];
