<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow">
            <base-table
                thead-classes="thead-light"
                tbody-classes="list"
                :columns="columns"
                :actions="actions"
                :url="`photographers/${schema.type}`"
                ref="photographerItems"
                v-on:action="actionItem"
            >
              <template v-if="create" v-slot:create>
                <div class="btn btn-success" @click="$goTo({ name: `photographers/${schema.type}/store`, params: $route.params })">Создать</div>
              </template>
              <template v-slot:type="row">
                <span class="type">{{ row.item.type_name }}</span>
              </template>
              <template v-slot:orientation="row">
                <span class="orientation">{{ row.item.orientation_name }}</span>
              </template>
              <template v-slot:status="row">
                <badge class="badge-dot mr-4" :type="row.item.status_type">
                  <i :class="`bg-${row.item.status_type}`"></i>
                  <span class="status">{{ row.item.status_name }}</span>
                </badge>
              </template>
            </base-table>
          </div>
        </div>
        <action-modal title="Удаление"
                      v-if="!!item"
                      :text="`Вы действительно хотите удалить запись ${item.name ?? item.size}?`"
                      action-text="Удалить"
                      :show-modal="deleteModal"
                      item="deleteModal"
                      v-on:action="destroyItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionModal from '@/views/modals/action-modal';

import { mapGetters } from "vuex";

import { api } from "@/plugins/axios";

export default {
  name: 'photographers/items',
  components: { ActionModal },
  computed: {
    ...mapGetters({ id: 'getUserId' }),
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    columns() {
      return require(`@/columns/${this.schema.type}`).default.filter(i => i.table);
    },
    actions() {
      let actions = [];
      try {
        actions = require(`@/actions/${this.schema.type}`).default;
      } catch (e) {}

      return [...require('@/actions/showEdit').default, ...actions];
    },
    create() {
      return this.schema.type !== 'orders';
    },
  },
  data() {
    return {
      item: null,
      deleteModal: false,
    };
  },
  methods: {
    actionItem(item, type, method) {
      if (method) {
        this[method](item, type);
      } else {
        this[`${type}Item`](item);
      }
    },
    goToItem(item, action) {
      this.$router.push({ name: `photographers/${this.schema.type}/${action}`, params: { photographerId: this.id, itemId: item.id } });
    },
    deleteItem(item) {
      this.item = item;
      this.deleteModal = true;
    },
    async destroyItem() {
      try {
        await api.delete(`photographers/${this.schema.type}/${this.item.id}`).then(() => {
          this.item = null;
          this.deleteModal = false;
        });
      } catch (e) {
        throw e;
      }

      await this.$refs['photographerItems'].getData();
    },
  }
}
</script>
