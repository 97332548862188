<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow mh-500">
            <loader v-if="loading" />
            <div v-if="model" class="card-body">
              <base-input :disabled="disabled" :error="errors[name] ? errors[name][0] : null" type="text" alternative label="Наименование" v-model="model.name"></base-input>
              <image-component v-if="action !== 'store'"
                               :show-input="!disabled"
                               :load-image="this.action !== 'store'"
                               :url="`photographers/watermarks/${this.$route.params.itemId}/image`"
              />
            </div>
            <div class="card-footer" v-if="action !== 'show'">
              <div class="display-flex justify-content-end">
                <base-button type="success" @click="sendForm()">{{ buttonText }}</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";

import ImageComponent from "@/views/components/forms/image";
import loader from '@/components/Loading';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'forms/items',
  components: { ImageComponent, loader },
  props: {
    item: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    redirectRoute: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Редактировать',
    },
    method: {
      type: String,
      default: 'put',
    },
    type: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({ loading: 'getIsLoading', errors: 'getErrors' }),
  },
  data() {
    return {
      model: null,
      orientations: [{ text: 'Горизонтальная', value: 'horizontal' }, { text: 'Вертикальная', value: 'vertical' }],
    };
  },
  created() {
    this.model = this.item;
  },
  unmounted() {
    this.setAppItem({ key: 'errors', value: {} });
  },
  methods: {
    ...mapActions(['setAppItem']),
    async sendForm() {
      this.setAppItem( { key: 'isLoading', value: true });
      try {
        let url = `${this.url}${this.item.id ? ('/' + this.item.id) : ''}`;
        await api[this.method](url, this.model).then(({ data }) => {
          let id = null;
          if (data.id) {
            id = this.item.id;
          } else {
            id = data.id;
          }
          let params = this.$route.params;
              params.itemId = id;
          this.$router.push({ name: this.redirectRoute, params });
        });
      } catch (e) {
        throw e;
      } finally {
        this.setAppItem( { key: 'isLoading', value: false });
      }
    },
  },
  watch: {
    item(data) {
      this.model = data;
    }
  }
}
</script>
