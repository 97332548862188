<template>
  <loading v-if="loading" />
  <div class="dropzone">
    <input type="file" :name="name" :accept="accepted" @change="uploadFile" :multiple="multiple" />
    <span class="dropzone-text">Перенесите в эту область файлы или выберите их нажав на эту область</span>
    <div class="dropzone-icons" v-if="images.length">
      <div class="dropzone-icon" v-for="image in images">
        <loading v-if="image.loading" />
        <span v-if="!image.loading" class="dropzone-icon-close" @click="removeImage(image)">&times;</span>
        <img :src="image.conversions[0].url" :alt="image.name" @load="image.loading = false; loadImage" />
        <span v-if="!image.loading" class="dropzone-icon-name">{{ image.name }}</span>
      </div>
      <div class="dropzone-icon" v-for="image in preloadingImages" :class="{ error: image.error }">
        <loading v-if="!image.error" />
        <span v-if="image.error"
              class="error"
              @click="removePreloading(image)"
              @mouseenter="showErrorText"
              @mouseleave="hideErrorText"
              :data-title="image.error_text"
        >&times;</span>
        <img :src="image.url" :alt="image.name" />
        <span class="dropzone-icon-name">{{ image.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";

import Loading from "@/components/Loading";

export default {
  name: 'component/drop-zone',
  components: { Loading },
  props: {
    uploadUrl: {
      type: String,
      default: '',
    },
    getUrl: {
      type: String,
      default: '',
    },
    deleteUrl: {
      type: String,
      default: '',
    },
    accepted: {
      type: String,
      default: 'image/*',
    },
    name: {
      type: String,
      default: 'images[]',
    },
    maxFileLoading: {
      type: Number,
      default: 2,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    maxFileSize: {
      type: Number,
      default: 500,
      description: 'In KB',
    },
    allowMessage: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      headers: api.defaults.headers.common,
      images: [],
      preloadingImages: [],
      loading: false,
      error: null,
    };
  },
  async created() {
    this.loading = true;
    try {
      const { data: { data } } = await api.get(this.getUrl);
      data.map(i => {
        i.loading = true;
        this.images.push(i);
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    showErrorText(e) {
      let title = e.target.getAttribute('data-title');
      let parent = e.target.closest('.dropzone-icon');

      let element = document.createElement('div');
          element.setAttribute('id', 'error_text');
          element.style.position = 'absolute';
          element.style.top = `${parent.offsetTop + parent.offsetHeight - 30}px`;
          element.style.left = `${parent.offsetLeft + 60 - 100}px`;
          element.style.width = '200px';
          element.style.background = '#000';
          element.style.color = '#fff';
          element.style.padding = '3px 7px';
          element.style.fontSize = '14px';
          element.style.zIndex = '11';

      let child = document.createElement('div');
          child.style.position = 'relative';
          child.innerText = title;

      let caret = document.createElement('div');
          caret.style.position = 'absolute';
          caret.style.top = '-13px';
          caret.style.left = '50%';
          caret.style.transform = 'translateX(-50%)';
          caret.style.width = '0';
          caret.style.height = '0';
          caret.style.borderLeft = '10px solid transparent';
          caret.style.borderRight = '10px solid transparent';
          caret.style.borderBottom = '10px solid black';

          child.append(caret);
          element.append(child);

      parent.after(element);
    },

    hideErrorText() {
      let block = document.getElementById('error_text');
      if (!!block) {
        block.remove();
      }
    },

    loadImage(e) {
      let width = e.target.naturalWidth;
      let height = e.target.naturalHeight;

      if (width > height) {
        e.target.classList.add('vertical');
      } else {
        e.target.classList.add('horizontal');
      }
    },

    async removeImage(image) {
      this.loading = true;
      try {
        await api.delete(`${this.deleteUrl}/${image.id}`);

        const index = this.images.indexOf(image);
        if (index > -1) {
          this.images.splice(index, 1);
        }
      } finally {
        this.loading = false;
      }
    },

    async uploadFile(e) {
      if (this.allowMessage) {
        this.$toast.error(this.allowMessage);
        e.target.value = null;
        e.preventDefault();
        return;
      }

      let items = e.target.files;
      this.preloadingImages = [...items].map(i => {
        i.url = URL.createObjectURL(i);
        i.error_text = null;
        i.error = false;
        return i;
      });

      let chunks = this.sliceIntoChunks(items);
      for (let i = 0; i < chunks.length; i++) {
        await this.uploadChunk(chunks[i]);
      }

      e.target.value = null;
      if (this.error) {
        throw this.error;
      }
    },

    async uploadChunk(images) {
      let items = new FormData();
      let sizeFailed = [];
      let failed = [];
      for (let i = 0; i < images.length; i++) {
        let accept = (images[i].size / 1024) < this.maxFileSize;
        if (accept) {
          items.append('images[]', images[i]);
        } else {
          sizeFailed.push(images[i]);
        }
      }

      if (items.getAll('images[]').length) {
        try {
          const { data: { data } } = await api.post(this.uploadUrl, items);
          data.map(i => {
            i.loading = true;
            this.images.push(i);
          });

          items.getAll('images[]').map(i => {
            this.removePreloading(i);
          });
        } catch (e) {
          failed = items.getAll('images[]');
          if (e.response && e.response.status === 403) {
            this.error = e;
          }
        } finally {
          [...sizeFailed, ...failed].map(i => {
            let sizeInclude = sizeFailed.includes(i);
            let failInclude = failed.includes(i);
            if (sizeInclude || failInclude) {
              const index = this.preloadingImages.indexOf(i);
              if (index > -1) {
                this.preloadingImages[index].error = true;
                if (sizeInclude) {
                  this.preloadingImages[index].error_text = `Размер превышает ${this.maxFileSize} килобайт`;
                } else {
                  this.preloadingImages[index].error_text = 'Ошибка загрузки фотографии. Попробуйте загрузить фотографию заново.';
                }
              }
            }
          });

          this.preloadingImages = [...this.preloadingImages];
        }
      } else {
        sizeFailed.map(i => {
          const index = this.preloadingImages.indexOf(i);
          this.preloadingImages[index].error = true;
          this.preloadingImages[index].error_text = `Размер превышает ${this.maxFileSize} килобайт`;
        });

        this.preloadingImages = [...this.preloadingImages];
      }
    },

    removePreloading(image) {
      const index = this.preloadingImages.indexOf(image);
      if (index > -1) {
        this.preloadingImages.splice(index, 1);
        this.hideErrorText();
      }
    },

    sliceIntoChunks(arr) {
      const res = [];

      arr = [...arr];
      for (let i = 0; i < arr.length; i += this.maxFileLoading) {
        const chunk = arr.slice(i, i + this.maxFileLoading);
        res.push(chunk);
      }
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>
  .dropzone {
    border: 1px dashed #1da1f2;
    border-radius: 5px;
    min-height: 100px;
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }

    .dropzone-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
    }

    .dropzone-icons {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 10px;

      .dropzone-icon {
        border-radius: 20px;
        overflow: hidden;
        width: 120px;
        height: 120px;
        position: relative;
        display: block;
        z-index: 10;
        margin-right: 10px;
        background: #fff;
        border: 1px solid #dadada;
        white-space: nowrap;

        .dropzone-icon-name {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          max-width: 100px;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 20px;
          background: rgba(255,255,255,.4);
          padding: 0 0.4em;
          border-radius: 3px;
          line-height: 1;
        }

        .dropzone-icon-close {
          position: absolute;
          right: 15px;
          top: 15px;
          color: white;
          z-index: 3;
          cursor: pointer;
          line-height: .1;
          font-size: 20px;
        }

        img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 200px;
          max-height: 200px;

          .vertical {
            max-height: 100%;
          }

          .horizontal {
            max-width: 100%;
          }
        }

        .error {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: red;
          font-weight: bold;
          line-height: .1;
          font-size: 60px;
          z-index: 1;
          margin-top: -5px;
          cursor: pointer;
        }

        &:hover,
        &.error {
          img {
            transform: scale(1.05, 1.05), translate(-50%, -50%);
            filter: blur(8px);
          }

          .dropzone-icon-name {
            display: block;
          }

          .dropzone-icon-close {
            color: red;
          }
        }

        &.error {
          .dropzone-icon-name {
            display: none;
          }

          .error {
            display: block;
          }
        }
      }
    }
  }
</style>
