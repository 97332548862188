<template>
  <loading v-if="loading" />
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <packages title="На период" :items="periods" type="periods" />
          <packages title="На количество фотосессий" :items="groups" type="groups" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Packages from "@/views/photographer/additional/packages";

import { api } from '@/plugins/axios';

export default {
  name: 'photographer/packages',

  components: { Loading, Packages },

  data() {
    return {
      periods: [],
      groups: [],
      loading: true,
    };
  },

  async created() {
    await this.getPackages();
  },

  methods: {
    async getPackages() {
      try {
        let { data: { data } } = await api.get('photographers/packages');

        this.periods = data.filter(i => i.type === 'periods');
        this.groups = data.filter(i => i.type === 'groups');
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
