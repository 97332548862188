<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow">
            <div class="card-body">
              <span class="alert alert-danger w-100 d-block">
                Каждый файл это обложка, которую будет выбирать пользователь.
                Не нужно создавать несколько одинаковых книг с разными обложками.
              </span>
              <span class="alert alert-danger w-100 d-block">
                Одна картинка не должна превышать 500 килобайт.
              </span>
              <div class="file-uploader">
                <input type="file"
                       multiple
                       @change="uploadImages"
                       accept="image/*"
                />
                <span class="center">Нажмите на этот блок и выберите файлы или перетащите файлы в этот блок.</span>
              </div>
            </div>
            <div class="card-footer item-images">
              <loader v-if="loading" />
              <div v-if="images.length" v-for="image in images" class="item-image">
                <span class="delete" @click="deleteItem(image)">&times;</span>
                <img :src="image.url" />
              </div>
              <div v-else class="alert alert-info w-100 d-block text-center">{{ text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <action-modal title="Удаление"
                  text="Вы действительно хотите удалить данную обложку?"
                  action-text="Удалить"
                  :show-modal="deleteModal"
                  item="deleteModal"
                  v-on:action="deleteImage"
    />
  </div>
</template>

<script>
import { api } from '@/plugins/axios';
import { mapActions, mapGetters } from "vuex";

import loader from '@/components/Loading';
import ActionModal from "@/views/modals/action-modal";

export default {
  name: 'photographers/books/images',
  components: { loader, ActionModal },
  computed: {
    ...mapGetters({ loading: 'getIsLoading' }),
    ...mapGetters({ item: 'photographer/getBook' }),
  },
  data() {
    return {
      images: [],
      text: null,
      deleteModal: false,
      model: null,
    };
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
  },
  async created() {
    await this.fetchPhotographerItem({
      itemId: this.$route.params.itemId,
      key: 'book',
      type: 'books',
    });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item })

    await this.getImages();
  },
  methods: {
    ...mapActions(['setAppItem']),
    ...mapActions('photographer', ['fetchPhotographerItem']),
    async getImages() {
      let images = [];
      this.text = 'Подгрузка обложек';

      this.setAppItem({ key: 'isLoading', value: true });
      try {
        const { data: { data } } = await api.get(`photographers/books/${this.$route.params.itemId}/images`);

        for (let image of data) {
          let conversions = image.conversions;
          if (conversions.length) {
            conversions = conversions.filter(i => i.name === 'images');
            if (conversions.length) {
              images.push({ id: image.id, name: image.name, url: conversions[0].url, original_url: image.url });
            }
          }
        }
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }

      this.text = 'Обложек не загружено';
      this.images = images;
    },
    async uploadImages(ev) {
      if (ev.target.files.length === 0) {
        return;
      }
      let storeImages = [...ev.target.files];
      this.setAppItem({ key: 'isLoading', value: true });
      try {
        let data = new FormData();
        for (let i = 0; i < storeImages.length; i++) {
          data.append('images[]', storeImages[i]);
        }

        await api.post(`photographers/books/${this.$route.params.itemId}/images/upload`, data,);
        await this.getImages();
      } catch (e) {
        ev.target.value = null;
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }
    },
    async deleteImage() {
      await api.delete(`photographers/books/${this.$route.params.itemId}/images/${this.model.id}/delete`).then(() => {
        const index = this.images.indexOf(this.model);
        if (index > -1) {
          this.images.splice(index, 1);
        }
        this.model = null;
        this.deleteModal = false;
      });
    },
    deleteItem(item) {
      this.model = item;
      this.deleteModal = true;
    },
  }
}
</script>

<style scoped lang="scss">
  .item-images {
    display: flex;
    flex-wrap: wrap;

    .item-image {
      position: relative;
      margin: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 150px;
        max-height: 150px;
      }

      .delete {
        position: absolute;
        top: 10px;
        right: 10px;
        background: red;
        border-radius: 50%;
        line-height: 0;
        color: #fff;
        width: 30px;
        height: 30px;
        text-align: center;
        padding-top: 14px;
        cursor: pointer;
      }
    }
  }
</style>
