import { createRouter, createWebHistory } from 'vue-router';

import DashboardLayout from "@/layout/DashboardLayout";

import Auth from '@/views/Auth';
import Dashboard from "@/views/Dashboard";
import Profile from "@/views/Profile";
import NotFound from '@/views/NotFound';
import adminRoutes from './admin';
import photographerRoutes from './photographer';

import {
    isAuthenticated,
    isNotAuthenticated,
    guardsSequence,
    includesRole
} from './guards.js';

const routes = [
    {
        path: "/",
        redirect: "/dashboard",
        component: DashboardLayout,
        children: [
            {
                path: '/',
                name: 'user',
                components: { default: Dashboard },
                beforeEnter: guardsSequence(isAuthenticated, includesRole(['isUser'])),

            },
            {
                path: 'profile',
                name: 'profile',
                components: { default: Profile },
                beforeEnter: guardsSequence(isAuthenticated, includesRole(['isPhotographer', 'isAdmin'])),
            },
            ...adminRoutes,
            ...photographerRoutes,
            { path: '/:pathMatch(.*)*', components: { default: NotFound } },
        ]
    },
    {
        path: "/",
        redirect: "/login",
        beforeEnter: isNotAuthenticated,
        component: Auth,
        children: [
            {
                path: '/login',
                name: 'login',
                components: { default: Auth },
            },
            {
                path: '/admin/login',
                name: 'admin/login',
                components: { default: Auth },
            }
        ],
    }
];

export default createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    routes: routes,
});
