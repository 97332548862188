import multiguard from 'vue-router-multiguard';
import store from '../stores';

/** @typedef {import('vue-router').NavigationGuard} NavigationGuard */
/** @typedef {'isAdmin'|'isPhotographer'|'isUser'|'isGroup'} RouterUserRole */

/**
 * Executes listed guards sequentially until they all pass or one of them redirects.
 * @param {...NavigationGuard} multipleGuards
 * @return {NavigationGuard}
 */
export const guardsSequence = (...multipleGuards) => multiguard(multipleGuards);

/**
 * Checks if user is authenticated, redirects them to `/` if they are.
 * @type {NavigationGuard}
 */
export const isNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/');
};

/**
 * Checks if user is authenticated, redirects them to `/login` if they aren't.
 * @type {NavigationGuard}
 */
export const isAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next('/login');
};


/**
 * Checks if user has one of the listed roles, redirects them to `/constructions` if they don't.

 * @param {...RouterUserRole} roles
 * @return {NavigationGuard}
 */
export const includesRole = (roles) => (to, from, next) => {
    if (roles.filter(role => store.getters[role]).length) {
        next();
        return;
    }

    ['admin', 'user', 'photographer', 'user'].forEach(i => {
        if (roles.filter(role => store.getters[`is${capitalizeFirstLetter(i)}`]).length) {
            next(`/${i === 'user' ? '/' : i}`);
            return;
        }
    });

    next('/');
};

function capitalizeFirstLetter(string) {
    return string.replace(/^./, string[0].toUpperCase());
}

/**
 * Checks if user has one of the listed roles, redirects them to `/constructions` if they do.
 * @param {string} redirectUrl
 * @param {...RouterUserRole} roles
 * @return {NavigationGuard}
 */
export const notIncludesRole = (redirectUrl, roles) => (to, from, next) => {
    if (roles.filter(role => store.getters[role]).length) {
        next(redirectUrl);
        return;
    }
    next();
};
