import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/routes';
import store from '@/stores';
import { api } from '@/plugins/axios';
import dashboard from '@/plugins/dashboard';
import Toaster from '@meforma/vue-toaster';

import "element-plus/lib/theme-chalk/index.css";

const token = localStorage.getItem('user-token');

if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const app = createApp(App);
app.config.globalProperties.$goTo = (data) => router.push(data);
app.config.globalProperties.$capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
app.config.globalProperties.$sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));
app.config.errorHandler = function (e, vm, info)  {
    try {
        if (e.response) {
            if ([404, 403].includes(e.response.status)) {
                vm.$toast.error(e.response.data.message);
            } else if (e.response.status === 422) {
                store._actions.setAppItem[0]({ key: 'errors', value: e.response.data.errors });
                vm.$toast.error('Некорректно заполненные данные');

                setTimeout(() => {
                    store._actions.setAppItem[0]({ key: 'errors', value: {} });
                }, 5000);
            } else if ([500, 501, 502].includes(e.response.status)) {
                vm.$toast.error('Неизвестная ошибка! Обратитесь к администратору.');
            }
        }
    } catch (error) {
        console.error(error);
    }
};
app
    .use(router)
    .use(store)
    .use(dashboard)
    .use(Toaster)
    .mount('#app');
