<template>
  <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
  >
    <div class="row align-items-center">
      <div class="col text-right table-search flex-wrap display-flex">
        <base-table-search v-if="!hideSearch" v-on:search="search" />
        <slot name="create"></slot>
      </div>
    </div>
  </div>

  <div class="table-responsive">
    <loader v-if="loading" />
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
        <tr>
          <slot name="columns" :columns="columns">
            <th v-for="(column, index) in columns"
                @click="changeOrder(column)"
                :style="{ width: !!column.width ? column.width : 'auto' }"
                :class="{ pointer: column.sortable }"
                :key="index"
            >
              {{ column.title }}
              &nbsp;
              <i class="fa fa-sort" v-if="column.sortable && sortValue !== column.name" />
              <i class="fa fa-sort-up" v-if="column.sortable && sortValue === column.name && orderValue === 'asc'" />
              <i class="fa fa-sort-down" v-if="column.sortable && sortValue === column.name && orderValue === 'desc'" />
            </th>
            <th v-if="actions.length || additionalActions"></th>
          </slot>
        </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-if="rows.length" v-for="(item, index) in rows" :key="index">
          <td v-for="column in columns" :key="column">
            <slot v-if="column.render" :name="column.name" :item="item" />
            <div v-else>
              {{ item[column.name] }}
            </div>
          </td>
          <td v-if="actions.length || additionalActions" class="text-right">
            <a v-for="action in actions"
               class="btn btn-secondary btn-sm"
               href="#"
               @click="$emit('action', item, action.action, action.method)"
               :style="action.color ? `color: ${action.color}` : ''"
               :class="{ 'd-none': !!action.condition && !evalCondition(item, action) }"
            >
              <i class="text-primary"
                 :class="action.icon"
                 :style="action.color ? `color: ${action.color} !important` : ''"
              />
            </a>
            <slot name="additional_actions" :item="item" />
          </td>
          <td v-else-if="additionalActions">
            <slot name="additional_actions" :item="item" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-end">
    <base-pagination v-if="isPagination && rows.length"
                     :total="total"
                     :per-page="perPage"
                     v-on:changePage="changePage"
    />
  </div>
</template>
<script>
import { api } from '@/plugins/axios';

export default {
  name: "base-table",
  emits: [ "action" ],
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    actions: {
      type: Array,
      default: () => [],
      description: 'Row actions',
    },
    url: {
      type: String,
      default: null,
    },
    order: {
      type: String,
      default: 'desc',
    },
    sort: {
      type: String,
      default: 'id',
    },
    type: {
      type: String,
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
    showPagination: {
      type: Boolean,
      default: true,
      description: "Show pagination",
    },
    perPage: {
      type: Number,
      default: 15,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    additionalActions: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  data() {
    return {
      sortValue: this.sort,
      orderValue: this.order,
      rows: [],
      page: 1,
      to: 0,
      total: 0,
      searchValue: null,
      cols: 0,
      isPagination: this.showPagination,
      loading: false,
    };
  },
  async created() {
    if (!this.$route.query.search) {
      await this.getData();
    }
  },
  methods: {
    evalCondition(item, action) {
      return eval(action.condition);
    },

    async search(query) {
      this.searchValue = query;

      await this.getData();
    },

    async changePage(page) {
      this.page = page;

      await this.getData();
    },

    async changeOrder(column) {
      if (!column.sortable) {
        return;
      }

      if (this.sortValue !== column.name) {
        this.sortValue = this.orderValue = null;
      }

      this.sortValue = column.sortableValue ? column.sortableValue : column.name;
      if (this.orderValue === null) {
        this.orderValue = 'desc';
      } else if (this.orderValue === 'asc') {
        this.sortValue = this.orderValue = null;
      } else if (this.orderValue === 'desc') {
        this.orderValue = 'asc';
      }

      await this.getData();
    },

    async getData() {
      this.loading = true;
      try {
        let params = {
          sort: this.sortValue,
          order: this.orderValue,
          page: this.page,
          per_page: this.perPage,
          search: this.searchValue
        };

        const { data: { data, meta } } = await api.get(this.url, { params });

        this.rows = data;
        this.to = meta.last_page;
        this.total = meta.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
  },
  watch: {
    async url(data) {
      if (!this.$route.query.search) {
        await this.getData();
      }
    }
  }
};
</script>
<style scoped lang="scss">
  .no-data {
    line-height: 24px;
    text-align: center;
  }

  .table-search {
    .form-group {
      margin-bottom: 0;
    }
  }
</style>
