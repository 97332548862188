export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        form: false,
        table: true,
    },
    {
        name: 'name',
        title: 'ФИО',
        sortable: true,
        type: 'text',
        table: true,
    },
    {
        name: 'email',
        title: 'E-mail',
        sortable: true,
        type: 'text',
        table: true,
    },
    {
        name: 'phone',
        title: 'Номер телефона',
        sortable: true,
        type: 'text',
        table: true,
    },
    {
        name: 'login',
        title: 'Логин',
        type: 'text',
        table: false,
    },
    {
        name: 'secret',
        title: 'Пароль',
        type: 'text',
        table: false,
    },
    {
        name: 'status',
        title: 'Статус',
        render: true,
        sortable: true,
        type: 'select',
        show_name: 'status_name',
        options: 'statuses',
        table: true,
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        sortable: true,
        form: false,
        table: true,
    },
];
