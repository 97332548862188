<template>
  <component :is="form"
             :item="item"
             :disabled="true"
             action="show"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'photographers/custom/show',
  computed: {
    ...mapGetters({
      id: 'getUserId',
    }),
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
    form() {
      return require(`@/views/components/forms/${this.schema.type}`).default;
    }
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
  },
  async created() {
    await this.fetchPhotographerItem({ itemId: this.$route.params.itemId, type: this.schema.type, key: this.schema.item });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item });
  },
  methods: {
    ...mapActions('photographer', ['fetchPhotographerItem']),
    ...mapActions(['setAppItem']),
  }
};
</script>
