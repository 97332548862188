<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow">
            <base-table
                thead-classes="thead-light"
                tbody-classes="list"
                :columns="columns"
                :actions="actions"
                :url="`photographers/groups/${$route.params.itemId}/galleries`"
                ref="photographerItems"
                v-on:action="actionItem"
            >
              <template v-if="create" v-slot:create>
                <div class="btn btn-success"
                     @click="$goTo({ name: `photographers/${type}/store`, params: $route.params })">Создать</div>
              </template>
              <template v-slot:type="row">
                <span class="type">{{ row.item.type_name }}</span>
              </template>
              <template v-slot:orientation="row">
                <span class="orientation">{{ row.item.orientation_name }}</span>
              </template>
              <template v-slot:status="row">
                <badge class="badge-dot mr-4" :type="row.item.status_type">
                  <i :class="`bg-${row.item.status_type}`"></i>
                  <span class="status">{{ row.item.status_name }}</span>
                </badge>
              </template>
            </base-table>
          </div>
        </div>
        <action-modal title="Удаление"
                      v-if="!!model"
                      :text="`Вы действительно хотите удалить запись ${model.name ?? model.size}?`"
                      action-text="Удалить"
                      :show-modal="deleteModal"
                      item="deleteModal"
                      v-on:action="destroyItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionModal from '@/views/modals/action-modal';

import { mapActions, mapGetters } from "vuex";

import { api } from "@/plugins/axios";

export default {
  name: 'photographers/groups/galleries',
  components: { ActionModal },
  computed: {
    ...mapGetters({ id: 'getUserId' }),
    columns() {
      return require(`@/columns/galleries`).default.filter(i => i.table);
    },
    actions() {
      let actions = [];
      try {
        actions = require(`@/actions/galleries`).default;
      } catch (e) {}

      return [...require('@/actions/showEdit').default, ...actions];
    },
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    create() {
      return this.type !== 'orders';
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
  },
  data() {
    return {
      model: null,
      deleteModal: false,
      type: 'groups/galleries',
    };
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
  },
  async created() {
    await this.fetchPhotographerItem({ itemId: this.$route.params.itemId, type: this.schema.type, key: this.schema.item });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item });
  },
  methods: {
    ...mapActions('photographer', ['fetchPhotographerItem']),
    ...mapActions(['setAppItem']),
    actionItem(item, type, method) {
      if (method) {
        this[method](item, type);
      } else {
        this[`${type}Item`](item);
      }
    },
    goToItem(item, action) {
      let params = this.$route.params;
          params.childId = item.id;
      this.$router.push({ name: `photographers/${this.type}/${action}`, params });
    },
    deleteItem(item) {
      this.model = item;
      this.deleteModal = true;
    },
    async destroyItem() {
      try {
        await api.delete(`photographers/groups/${this.$route.params.itemId}/galleries/${this.model.id}`).then(() => {
          this.model = null;
          this.deleteModal = false;
        });
      } catch (e) {
        throw e;
      }

      await this.$refs['photographerItems'].getData();
    },
  }
}
</script>
