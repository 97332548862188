export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'phone',
        title: 'Телефон',
        table: true,
        type: 'text',
    },
    {
        name: 'email',
        title: 'E-mail',
        table: true,
        type: 'text',
    },
    {
        name: 'login',
        title: 'Логин',
        type: 'text',
    },
    {
        name: 'secret',
        title: 'Пароль',
        type: 'text',
    },
    {
        name: 'date',
        title: 'Дата проведения фотосессии',
        table: true,
        type: 'date',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        table: true,
    },
];
