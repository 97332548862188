export default [
    ...require('./delete').default,
    {
        icon: 'fa fa-images',
        action: 'galleries',
        title: 'Галереи',
        method: 'goToItem',
    },
    {
        icon: 'fa fa-users',
        action: 'users',
        title: 'Пользователи',
        method: 'goToItem',
    }
];
