export default [
    {
        name: 'Книги',
        relation: 'books',
        type: 'many',
        isset: 'Книги не добавлены',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
            { field: 'size', name: 'Размер' },
            { field: 'pivot_price', name: 'Цена', default: 'price' },
            { field: 'pivot_price_on_page', name: 'Цена за страницу', default: 'price_on_page' },
        ],
    },
    {
        name: 'Календари',
        relation: 'calendars',
        type: 'many',
        isset: 'Календари не добавлены',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
            { field: 'size', name: 'Размер' },
            { field: 'pivot_price', name: 'Цена', default: 'price' },
        ],
    },
    {
        name: 'Размеры',
        relation: 'sizes',
        type: 'many',
        isset: 'Размеры не добавлены',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
            { field: 'size', name: 'Размер' },
            { field: 'pivot_price', name: 'Цена', default: 'price' },
        ],
    },
    {
        name: 'Магниты',
        relation: 'magnets',
        type: 'many',
        isset: 'Магниты не добавлены',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
            { field: 'size', name: 'Размер' },
            { field: 'pivot_price', name: 'Цена', default: 'price' },
        ],
    },
    {
        name: 'Обработка',
        relation: 'processings',
        type: 'one',
        isset: 'Обработка не выбрана',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
            { field: 'pivot_price', name: 'Цена', default: 'price' },
            { field: 'pivot_count', name: 'Порог', default: 'count' },
            { field: 'pivot_after_limit_count', name: 'Кол-во', default: 'after_limit_count' },
            { field: 'pivot_after_limit_price', name: 'Цена за кол-во после порога', default: 'after_limit_price' },
        ],
    },
    {
        name: 'Скидки',
        relation: 'discounts',
        type: 'one',
        isset: 'Скидка не выбрана',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
            { field: 'amount', name: 'Сумма' },
            { field: 'percent', name: 'Процент' },
        ]
    },
    {
        name: 'Водяной знак',
        relation: 'watermarks',
        type: 'one',
        isset: 'Водяной знак не выбран',
        data: [],
        fields: [
            { field: 'name', name: 'Наименование' },
        ],
    },
];
