<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow mh-500">
            <loader v-if="loading" />
            <div v-if="!!model" class="card-body">
              <div v-for="field in fields">
                <base-input
                    :disabled="disabled"
                    v-if="['text', 'date'].includes(field.type)"
                    :type="field.type"
                    alternative
                    :label="field.title"
                    :hint="field.hint"
                    v-model="model[field.name]"
                    :error="errors[field.name] ? errors[field.name][0] : null"
                />
                <base-checkbox
                    :disabled="disabled"
                    v-if="field.type === 'checkbox'"
                    :checked="model[field.name] === 1"
                    v-model="model[field.name]"
                >{{ field.title }}</base-checkbox>
                <base-select
                    :disabled="disabled"
                    v-if="field.type === 'select'"
                    alternative
                    :label="field.title"
                    :options="this[field.options]"
                    v-model="model[field.name]"
                    :error="errors[field.name] ? errors[field.name][0] : null"
                />
              </div>
              <div v-if="buttons.length">
                <base-button v-for="button in buttons"
                             @click="$goTo({ name: `photographers/${type}/${button.route}`, params: $route.params });"
                             size="sm"
                             outline
                             :type="!!button.type ? button.type : 'secondary'"
                             class="d-xs-inline-block d-lg-none"
                >
                  <i :class="`${button.icon}`" />
                </base-button>
                <base-button v-for="button in buttons"
                             @click="$goTo({ name: `photographers/${type}/${button.route}`, params: $route.params });"
                             size="sm"
                             outline
                             :type="!!button.type ? button.type : 'secondary'"
                             class="d-xs-none d-lg-inline-block"
                >
                  {{ button.name }}
                </base-button>
              </div>
              <div v-if="action !== 'show'" class="display-flex justify-content-end">
                <base-button type="success" @click="sendForm()">{{ buttonText }}</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";
import {mapActions, mapGetters} from "vuex";

import loader from "@/components/Loading";

export default {
  name: 'forms/items',
  components: { loader },
  props: {
    item: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    redirectRoute: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: [],
    },
    buttons: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Редактировать',
    },
    method: {
      type: String,
      default: 'put',
    },
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: null,
      orientations: [{ text: 'Горизонтальная', value: 'horizontal' }, { text: 'Вертикальная', value: 'vertical' }],
    };
  },
  computed: {
    ...mapGetters({ loading: 'getIsLoading', errors: 'getErrors' }),
  },
  created() {
    this.model = this.item;
  },
  unmounted() {
    this.setAppItem({ key: 'errors', value: {} });
  },
  methods: {
    ...mapActions(['setAppItem']),
    async sendForm() {
      this.setAppItem( { key: 'isLoading', value: true });
      try {
        let url = `${this.url}${this.item.id ? ('/' + this.item.id) : ''}`;
        await api[this.method](url, this.model).then(({ data }) => {
          let id = null;
          if (data.id) {
            id = this.item.id;
          } else {
            id = data.id;
          }
          let params = this.$route.params;
              params.itemId = id;
          this.$router.push({ name: this.redirectRoute, params });
        });
      } catch (e) {
        throw e;
      } finally {
        this.setAppItem( { key: 'isLoading', value: false });
      }
    },
  },
  watch: {
    item(data) {
      this.model = data;
    }
  }
}
</script>
