<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow">
            <base-table
                thead-classes="thead-light"
                tbody-classes="list"
                :columns="columns"
                :actions="actions"
                url="admin/photographers"
                ref="adminPhotographer"
                v-on:action="actionItem"
            >
              <template v-slot:status="row">
                <badge class="badge-dot mr-4" :type="row.item.status_type">
                  <i :class="`bg-${row.item.status_type}`"></i>
                  <span class="status">{{ row.item.status_name }}</span>
                </badge>
              </template>
            </base-table>
          </div>
        </div>
        <action-modal title="Удаление"
                      text="Вы действительно хотите удалить этого фотографа?"
                      action-text="Удалить"
                      :show-modal="deleteModal"
                      item="deleteModal"
                      v-on:action="deleteUser"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ActionModal from '@/views/modals/action-modal';

import { api } from "@/plugins/axios";
import { mapActions } from "vuex";

export default {
  components: { ActionModal },
  data() {
    return {
      columns: require('@/columns/users').default.filter(i => i.table),
      actions: [
          ...require('@/actions/all').default,
          ...[
            {
              icon: 'ni ni-single-02',
              action: 'auth',
              title: 'Авторизоваться',
              color: 'black',
            }
          ]
      ],
      deleteModal: false,
    };
  },
  methods: {
    ...mapActions(['auth', 'logout']),
    actionItem(item, type) {
      this[`${type}Item`](item);
    },
    showItem(item) {
      this.$router.push({ name: 'admin/photographers/show', params: { photographerId: item.id } });
    },
    editItem(item) {
      this.$router.push({ name: 'admin/photographers/edit', params: { photographerId: item.id } });
    },
    deleteItem(item) {
      this.id = item.id;
      this.deleteModal = true;
    },
    async deleteUser() {
      try {
        await api.delete(`admin/photographers/${this.id}`).then(() => {
          this.id = null;
          this.deleteModal = false;
        });
      } catch (e) {
        throw e;
      }

      await this.$refs['adminPhotographer'].getData();
    },
    async authItem(item) {
      try {
        await api.post(`admin/photographers/${item.id}/auth`).then(async ({ data }) => {
          this.logout();
          await this.auth(data.access_token);

          this.$router
              .push({ name: 'photographers' })
              .catch(e => {
              });
        });
      } catch (e) {
        throw e;
      }
    },
  }
};
</script>
<style></style>
