import Dashboard from "@/views/photographer/dashboard";
import Items from "@/views/photographer/items";
import BookImages from '@/views/photographer/items/books/images';
import CalendarImage from '@/views/photographer/items/calendars/image';

import { guardsSequence, includesRole, isAuthenticated } from "@/routes/guards";

const check = guardsSequence(isAuthenticated, includesRole(['isPhotographer']));

const items = [
    { item: 'books',  title: 'Книги' },
    { item: 'calendars', title: 'Календари' },
    { item: 'sizes', title: 'Размеры' },
    { item: 'magnets', title: 'Магниты' },
    { item: 'processings', title: 'Обработка' },
    { item: 'watermarks', title: 'Водяные знаки' },
    { item: 'discounts', title: 'Скидки' },
    { item: 'groups', title: 'Группы' },
    { item: 'orders', title: 'Заказы' },
];

export default [
    {
        path: '/photographers',
        name: 'photographers',
        components: { default: Dashboard },
        title: 'Главная',
        beforeEnter: check,
    },
    ...items.map(i => {
        return {
            path: `photographers/${i.item}`,
            name: `photographers/${i.item}`,
            components: { default: Items },
            title: i.title,
            links: ['photographers'],
            beforeEnter: check,
            breadcrumb_type: 'main',
        }
    }),
    ...items.map(i => {
        return [
            { route: 'show', title: 'Просмотр' },
            { route: 'edit', title: 'Редактирование' },
            { route: 'store', title: 'Создание' },
        ].map(j => {
            return {
                path: j.route === 'store'
                    ? `photographers/${i.item}/store`
                    : `photographers/${i.item}/:itemId/${j.route}`,
                name: `photographers/${i.item}/${j.route}`,
                components: {
                    default: !['calendars', 'groups', 'watermarks'].includes(i.item)
                        ? require(`@/views/photographer/items/${j.route}`).default
                        : require(`@/views/photographer/items/custom/${j.route}`).default
                },
                title: j.title,
                links: ['photographers', `photographers/${i.item}`],
                beforeEnter: check,
            };
        });
    }).flat(),
    {
        path: `photographers/books/:itemId/images`,
        name: `photographers/books/images`,
        components: { default: BookImages },
        title: 'Обложки',
        links: ['photographers', `photographers/books`],
        beforeEnter: check,
    },
    {
        path: `photographers/calendars/:itemId/image`,
        name: `photographers/calendars/image`,
        components: { default: CalendarImage },
        title: 'Изображение',
        links: ['photographers', `photographers/calendars`],
        beforeEnter: check,
    },
    {
        path: `photographers/groups/:itemId/galleries`,
        name: `photographers/groups/galleries`,
        components: { default: require(`@/views/photographer/items/groups/galleries`).default },
        title: 'Галереи',
        links: ['photographers', `photographers/groups`],
        beforeEnter: check,
        breadcrumb_type: 'secondary',
    },
    {
        path: `photographers/groups/:itemId/galleries/:childId/show`,
        name: `photographers/groups/galleries/show`,
        components: { default: require(`@/views/photographer/items/custom/childs/show`).default },
        title: 'Просмотр',
        links: ['photographers', 'photographers/groups', 'photographers/groups/galleries'],
        beforeEnter: check,
    },
    {
        path: `photographers/groups/:itemId/galleries/:childId/edit`,
        name: `photographers/groups/galleries/edit`,
        components: { default: require(`@/views/photographer/items/custom/childs/edit`).default },
        title: 'Редактирование',
        links: ['photographers', 'photographers/groups', 'photographers/groups/galleries'],
        beforeEnter: check,
    },
    {
        path: `photographers/groups/:itemId/galleries/:childId/images`,
        name: `photographers/groups/galleries/images`,
        components: { default: require(`@/views/photographer/items/groups/galleries/images`).default },
        title: 'Фотографии',
        links: ['photographers', 'photographers/groups', 'photographers/groups/galleries'],
        beforeEnter: check,
    },
    {
        path: `photographers/groups/:itemId/galleries/store`,
        name: `photographers/groups/galleries/store`,
        components: { default: require(`@/views/photographer/items/custom/childs/store`).default },
        title: 'Создание',
        links: ['photographers', 'photographers/groups', 'photographers/groups/galleries'],
        beforeEnter: check,
    },
    {
        path: `photographers/groups/:itemId/users`,
        name: `photographers/groups/users`,
        components: { default: require('@/views/photographer/items/groups/users').default },
        title: 'Пользователи',
        links: ['photographers', `photographers/groups`],
        beforeEnter: check,
        breadcrumb_type: 'secondary',
    },
    {
        path: `photographers/packages`,
        name: `photographers/packages`,
        components: { default: require('@/views/photographer/packages').default },
        title: 'Пакеты услуг',
        links: [''],
        beforeEnter: check,
    },
];
