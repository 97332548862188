export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        form: false,
        table: true,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        type: 'text',
        table: true,
    },
    {
        name: 'price',
        title: 'Цена',
        type: 'text',
        table: true,
    },
    {
        name: 'count',
        title: 'Кол-во',
        sortable: true,
        type: 'text',
        table: true,
    },
    {
        name: 'type',
        title: 'Тип',
        render: true,
        sortable: true,
        type: 'select',
        show_name: 'type_name',
        options: 'types',
        table: true,
    },
    {
        name: 'status',
        title: 'Статус',
        render: true,
        sortable: true,
        type: 'select',
        show_name: 'status_name',
        options: 'statuses',
        table: true,
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        sortable: true,
        form: false,
        table: true,
    },
];
