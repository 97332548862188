<template>
  <component :is="form"
             :url="`photographers/${schema.type}`"
             :item="getItem()"
             :redirect-route="`photographers/${schema.type}`"
             button-text="Создать"
             method="post"
             action="store"
  />
</template>

<script>
import ItemsForm from '@/views/components/forms/items';

import { mapGetters, mapActions } from "vuex";

export default {
  name: 'photographers/items/show',
  components: { ItemsForm },
  computed: {
    ...mapGetters({ id: 'getUserId' }),
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
    form() {
      return require(`@/views/components/forms/${this.schema.type}`).default;
    },
    fields() {
      return require(`@/columns/${this.schema.type}`).default.filter(i => i.form === undefined);
    },
  },
  methods: {
    getItem() {
      let item = {};
      for (let field of this.fields) {
        item[field.name] = field.type === 'checkbox' ? false : '';
      }
      return item;
    },
  }
};
</script>
