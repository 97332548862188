<template>
  <additional-block v-if="additional" :is-loading="additionalLoading">
    <template v-slot:title>
      <div class="display-flex justify-content-between">
        <span>{{ relation.name }}</span>
        <base-button @click="$parent.additional = false; selected = null;" type="primary" size="sm">Закрыть</base-button>
      </div>
    </template>
    <template v-if="selected" v-slot:data>
      <div v-if="!disabled">
        <div v-if="selected.type === 'many' || (selected.type === 'one' && !selected.data.length)">
          <base-select
              alternative
              label=""
              @change="openAdditionalFields"
              :options="items.filter(i => !selected.data.map(j => j.id).includes(i.value))"
              v-model="selectedItem"
          />
          <div v-if="opened">
            <base-input v-for="input in selected.fields.filter(i => i.default)"
                        alternative
                        :label="input.name"
                        v-model="selectedFields[input.default]"
            />
            <div class="display-flex justify-content-end mb-3">
              <div class="btn btn-success" @click="attachItem()">Добавить</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selected.data.length">
        <div class="alert alert-default position-relative" v-for="datum in selected.data">
          <i v-if="!disabled" class="fa fa-trash position-absolute top-15 right-15 pointer" @click="detachItem(datum)" />
          <span v-for="field in selected.fields">
                  {{ field.name }}: {{ datum[field.field] ?? datum[field.default] }}<br />
                </span>
        </div>
      </div>
      <div v-else class="alert alert-secondary text-center">
        {{ selected.isset }}
      </div>
    </template>
  </additional-block>
</template>

<script>
import { api } from "@/plugins/axios";

import AdditionalBlock from "@/views/components/additional-block";

export default {
  name: 'additional/items',

  components: { AdditionalBlock },

  props: {
    additional: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    url: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      opened: false,
      selectedItem: null,
      selectedFields: {},
      relation: null,
      selected: null,
      items: [],
      additionalLoading: false,
      relations: require(`@/types/photographer/groups`).default,
    };
  },

  methods: {
    async getItems() {
      return await api.get(`photographers/${this.selected.relation}`).then(({ data: { data } }) => {
        this.items = data.map(i => {
          return {
            text: i.name ?? i.size,
            value: i.id,
          };
        });
      });
    },

    openAdditionalFields() {
      this.opened = this.selectedItem !== '';
    },

    async attachItem() {
      this.additionalLoading = true;

      try {
        await api.post(
            `${this.url}/${this.selected.relation}/${this.selectedItem}/attach`,
            this.selectedFields
        ).then(async () => {
          await this.getRelation();

          this.opened = false;
          this.selectedItem = null;
          Object.keys(this.selectedFields).map(i => {
            this.selectedFields[i] = null
          });
        });
      } catch (e) {
        throw e;
      } finally {
        this.additionalLoading = false;
      }
    },

    async detachItem(item) {
      this.additionalLoading = true;

      try {
        await api.delete(`${this.url}/${this.selected.relation}/${item.id}/detach`).then(() => {
          this.selected.data = this.selected.data.filter(i => i.id !== item.id);
        });
      } catch (e) {
        throw e;
      } finally {
        this.additionalLoading = false;
      }
    },

    setRelation() {
      this.selected = this.relations.filter(i => i.relation === this.relation.relation)[0];
    },

    async getRelation() {
      await api.get(`${this.url}/${this.selected.relation}`)
          .then(({ data: { data } }) => {
            this.selected.data = data;
          });
    },

    async showRelationBlock(relation) {
      if (!!this.selected && relation.relation === this.selected.relation) {
        return;
      }

      this.relation = relation;
      this.additionalLoading = true;

      try {
        this.setRelation(relation);
        await this.getRelation();
        if (!this.disabled) {
          await this.getItems();
        }
      } catch (e) {
        throw e;
      } finally {
        this.additionalLoading = false;
      }
    },
  },

  watch: {
    additional(data) {
      if (!data) {
        this.selected = null;
      }
    },
  }
};
</script>
