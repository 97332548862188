const state = {
    isLoading: false,
    percentage: null,
    isShowBlock: false,
    mainBreadcrumb: null,
    secondaryBreadcrumb: null,
    thirdlyBreadcrumb: null,
    errors: {},
};

const getters = {
    getIsLoading: state => state.isLoading,
    getPercentage: state => state.percentage,
    isShowBlock: state => state.isShowBlock,
    getMainBreadcrumb: state => state.mainBreadcrumb,
    getSecondaryBreadcrumb: state => state.secondaryBreadcrumb,
    getThirdlyBreadcrumb: state => state.thirdlyBreadcrumb,
    getErrors: state => state.errors,
};

const actions = {
    setAppItem({ commit }, { key, value }) {
        commit('setAppItem', { key, value });
    }
};

const mutations = {
    setAppItem(state, { key, value }) {
        state[key] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
