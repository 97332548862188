<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="transition-all" :class="{ 'col-lg-12': !showUser, 'col-lg-8': showUser }">
          <div class="card shadow">
            <base-table
                thead-classes="thead-light"
                tbody-classes="list"
                :columns="columns"
                :additional-actions="true"
                :url="`photographers/groups/${$route.params.itemId}/users`"
                ref="photographerUsers"
            >
              <template v-slot:status="row">
                <badge class="badge-dot mr-4" :type="row.item.status_type">
                  <i :class="`bg-${row.item.status_type}`"></i>
                  <span class="status">{{ row.item.status_name }}</span>
                </badge>
              </template>
              <template v-slot:additional_actions="row">
                <a class="btn btn-secondary btn-sm"
                   href="#"
                   @click="showUser = true; user = row.item;"
                >
                  <i class="text-black ni ni-badge"/>
                </a>
                <a class="btn btn-secondary btn-sm"
                   href="#"
                   @click="this.deleteItem(row.item)"
                >
                  <i class="text-danger fa fa-trash"/>
                </a>
              </template>
            </base-table>
          </div>
        </div>

        <user :user="user" :additional="showUser" v-on:refreshTable="refreshTable">
          <template v-slot:additional_actions>
            <base-button class="btn-sm" type="secondary" @click="openGallery()">Открыть галерею</base-button>
          </template>
        </user>

        <action-modal title="Удаление"
                      text="Вы действительно хотите удалить этого пользователя из данной группы?"
                      action-text="Удалить"
                      :show-modal="deleteModal"
                      item="deleteModal"
                      v-on:action="deleteUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/plugins/axios';

import ActionModal from '@/views/modals/action-modal';
import BaseButton from "@/components/BaseButton";

import User from '@/views/photographer/additional/user';

import { mapActions, mapGetters } from "vuex";

export default {
  name: 'photographers/groups/users',
  components: { BaseButton, ActionModal, User },
  computed: {
    ...mapGetters({ id: 'getUserId' }),
    columns() {
      return require(`@/columns/users`).default.filter(i => i.table);
    },
    actions() {
      return require('@/actions/show').default;
    },
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    item() {
      return this.$store.getters[`photographer/get${this.$capitalize(this.schema.item)}`];
    },
  },
  data() {
    return {
      user: null,
      deleteModal: false,
      userId: null,
      showUser: false,
    };
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
  },
  async created() {
    await this.fetchPhotographerItem({ itemId: this.$route.params.itemId, type: this.schema.type, key: this.schema.item });

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item });
  },
  methods: {
    ...mapActions('photographer', ['fetchPhotographerItem']),
    ...mapActions(['setAppItem']),

    async deleteUser() {
      try {
        await api.delete(`photographers/groups/${this.$route.params.itemId}/users/${this.userId}`).then(() => {
          if (this.userId === this.user.id) {
            this.user = null;
            this.showUser = false;
          }
          this.userId = null;
          this.deleteModal = false;
        });
      } catch (e) {
        throw e;
      }

      await this.$refs['photographerUsers'].getData();
    },

    deleteItem(item) {
      this.userId = item.id;
      this.deleteModal = true;
    },

    async refreshTable() {
      await this.$refs['photographerUsers'].getData();
    },

    async openGallery() {
      try {
        this.loading = true;

        let {data} = await api.get(`photographers/groups/${this.$route.params.itemId}/users/${this.user.id}/gallery`);

        this.$router.push({
          name: 'photographers/groups/galleries/edit',
          params: {...this.$route.params, ...{ childId: data.id }}
        });
      } catch(e) {
        this.$toast.error('Упс, что-то пошло не так. Свяжитесь с администратором.');
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
