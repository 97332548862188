<template>
  <div>
    <base-input v-if="this.showInput" label="Аватар" type="file" @change="changeAvatar" accept="image/*" />
    <div v-if="avatar" class="item-images">
      <div class="item-image">
        <img :src="avatar.conversions.length ? avatar.conversions[0].url : avatar.url" />
      </div>
      <div class="mt-3" v-if="this.showInput">
        <div class="btn btn-danger" @click="deleteModal = true">Удалить</div>
      </div>
    </div>
    <action-modal title="Удаление"
                  text="Вы действительно хотите удалить это изображение?"
                  action-text="Удалить"
                  :show-modal="deleteModal"
                  item="deleteModal"
                  v-on:action="deleteAvatar"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ActionModal from "@/views/modals/action-modal";

export default {
  name: 'forms/image',

  components: { ActionModal },

  props: {
    url: {
      type: String,
      default: '',
    },
    showInput: {
      type: Boolean,
      default: false,
    },
    loadImage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({ avatar: 'photographer/getAvatar' }),
  },

  data() {
    return {
      deleteModal: false,
    };
  },

  async created() {
    if (this.loadImage) {
      await this.fetchPhotographerItemAvatar({ url: this.url });
    }
  },

  methods: {
    ...mapActions('photographer', ['fetchPhotographerItemAvatar', 'setPhotographerItemAvatar', 'removePhotographerItemAvatar']),

    async changeAvatar(e) {
      await this.setPhotographerItemAvatar({ url: this.url, e });
    },

    async deleteAvatar(e) {
      await this.removePhotographerItemAvatar({ url: this.url, e });

      this.deleteModal = false;
    },
  }
}
</script>
