export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
        form: false,
    },
    {
        name: 'photographer_name',
        title: 'Фотограф',
        table: true,
        type: 'text',
    },
    {
        name: 'user_name',
        title: 'Пользователь',
        table: true,
        type: 'text',
    },
    {
        name: 'group_name',
        title: 'Группа',
        table: true,
        type: 'text',
    },
    {
        name: 'gallery_name',
        title: 'Галлерея',
        table: true,
        type: 'text',
    },
    {
        name: 'amount',
        title: 'Сумма',
        table: true,
        type: 'text',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        form: false,
        table: true,
    },
    {
        name: 'updated_at',
        title: 'Дата последнего обновления',
        form: false,
        table: true,
    },
];
