import { api } from '@/plugins/axios';

const state = {
    token: localStorage.getItem('user-token') || null,
    isGroup: localStorage.getItem('user-is-group') === 'true' || false,
    isUser: localStorage.getItem('user-is-user') === 'true' || false,
    isAdmin: localStorage.getItem('user-is-admin') === 'true' || false,
    isPhotographer: localStorage.getItem('user-is-photographer') === 'true' || false,
    id: localStorage.getItem('user-id') || null,
    name: localStorage.getItem('user-name') || '',
    email: localStorage.getItem('user-email') || '',
    phone: localStorage.getItem('user-phone') || '',
    login: localStorage.getItem('user-login') || '',
    secret: localStorage.getItem('user-secret') || '',
    avatar: localStorage.getItem('user-avatar') || '',
};

const getters = {
    isAuthenticated: state => !!state.token,
    getToken: state => state.token,
    isGroup: state => !!state.isGroup,
    isUser: state => !!state.isUser,
    isAdmin: state => !!state.isAdmin,
    isPhotographer: state => !!state.isPhotographer,
    getUserId: state => state.id,
    getUserName: state => state.name,
    getUserEmail: state => state.email,
    getUserPhone: state => state.phone,
    getUserLogin: state => state.login,
    getUserSecret: state => state.secret,
    getUserAvatar: state => state.avatar,
};

const actions = {
    async loginWithoutToken({ commit, dispatch }, { login, password }) {
        let response = await api
            .post('/login', {
                login: login,
                password: password
            });

            if (response.data.access_token) {
                localStorage.setItem('user-token', response.data.access_token);
                api.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
                commit('setUserItem', { key: 'token', value: response.data.access_token });

                await dispatch('getUserData');

                return true;
            }

        return false;
    },

    async auth({ commit, dispatch }, token) {
        localStorage.setItem('user-token', token);
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        commit('setUserItem', { key: 'token', value: token });

        await dispatch('getUserData');
    },

    async loginWithToken({ commit, dispatch }, token) {
        if (token) {
            localStorage.setItem('user-token', token);
            api.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit('setUserItem', { key: 'token', value: token });

            await dispatch('getUserData');

            return true;
        }
    },

    async getUserData({ commit }) {
        const { data } = await api.get('/user');

        let isAdmin = data.roles.filter(i => i.slug === 'admin').length > 0;
        let isPhotographer = data.roles.filter(i => i.slug === 'photographer').length > 0;
        let isGroup = data.roles.filter(i => i.slug === 'group').length > 0;
        let isUser = data.roles.filter(i => i.slug === 'user').length > 0;

        localStorage.setItem('user-id', data.id);
        localStorage.setItem('user-name', data.name);
        localStorage.setItem('user-email', data.email);
        localStorage.setItem('user-phone', data.phone);
        localStorage.setItem('user-login', data.login);
        localStorage.setItem('user-is-admin', isAdmin ? 'true' : 'false');
        localStorage.setItem('user-is-group', isGroup ? 'true' : 'false');
        localStorage.setItem('user-is-photographer', isPhotographer ? 'true' : 'false');
        localStorage.setItem('user-is-user', isUser ? 'true' : 'false');
        localStorage.setItem('user-secret', data.secret);
        localStorage.setItem('user-avatar', data.avatar ? data.avatar.conversions[0].url : '');

        commit('setUserItem', { key: 'id', value: data.id });
        commit('setUserItem', { key: 'name', value: data.name });
        commit('setUserItem', { key: 'phone', value: data.phone });
        commit('setUserItem', { key: 'email', value: data.email });
        commit('setUserItem', { key: 'isAdmin', value: isAdmin });
        commit('setUserItem', { key: 'isGroup', value: isGroup });
        commit('setUserItem', { key: 'isUser', value: isUser });
        commit('setUserItem', { key: 'isPhotographer', value: isPhotographer });
        commit('setUserItem', { key: 'login', value: data.login });
        commit('setUserItem', { key: 'secret', value: data.secret });
        commit('setUserItem', { key: 'avatar', value: data.avatar ? data.avatar.conversions[0].url : '' });
    },

    logout({ commit }) {
        delete api.defaults.headers.common.Authorization;
        localStorage.clear();
        commit('setUserItem', { key: 'token', value: null });
        commit('setUserItem', { key: 'name', value: '' });
        commit('setUserItem', { key: 'id', value: null });
        commit('setUserItem', { key: 'phone', value: '' });
        commit('setUserItem', { key: 'email', value: '' });
        commit('setUserItem', { key: 'login', value: '' });
        commit('setUserItem', { key: 'secret', value: '' });
        commit('setUserItem', { key: 'isAdmin', value: false });
        commit('setUserItem', { key: 'isGroup', value: false });
        commit('setUserItem', { key: 'isUser', value: false });
        commit('setUserItem', { key: 'isPhotographer', value: false });
        commit('setUserItem', { key: 'avatar', value: '' });
    }
};

const mutations = {
    setUserItem(state, { key, value }) {
        state[key] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
