<template>
  <ul class="pagination">
    <li class="page-item prev-page" :class="{ disabled: !hasPrev() }">
      <a class="page-link" aria-label="Previous" @click="changePage(page - 1)">
        <span aria-hidden="true"
          ><i class="fa fa-angle-left" aria-hidden="true"></i
        ></span>
      </a>
    </li>
    <li class="page-item prev-page disabled" v-if="hasFirst()"><a class="page-link">...</a></li>
    <li
      class="page-item"
      :class="{ active: item === page }"
      :key="item"
      v-for="item in pages"
    >
      <a class="page-link" @click="changePage(item)">{{ item }}</a>
    </li>
    <li class="page-item prev-page disabled" v-if="hasLast()"><a class="page-link">...</a></li>
    <li class="page-item next-page" :class="{ disabled: !hasNext() }">
      <a class="page-link" aria-label="Next" @click="changePage(page + 1)">
        <span aria-hidden="true"
          ><i class="fa fa-angle-right" aria-hidden="true"></i
        ></span>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "base-pagination",
  props: {
    pageRange: {
      type: Number,
      default: 3,
      description: "Page count in pagination",
    },
    perPage: {
      type: Number,
      default: 15,
      description:
        "Pagination per page. Should be specified with total or pageCount",
    },
    total: {
      type: [String, Number],
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage",
    },
  },
  data() {
    return {
      input: '',
      page: 1
    }
  },
  methods: {
    hasFirst: function () {
      return this.rangeStart !== 1
    },
    hasLast: function () {
      return this.rangeEnd < this.totalPages
    },
    hasPrev: function () {
      return this.page > 1
    },
    hasNext: function () {
      return this.page < this.totalPages
    },
    changePage: function (page) {
      if (page > 0 && page <= this.totalPages) {
        this.page = page;
        this.$emit('changePage', page)
      }
    }
  },

  computed: {
    pages: function () {
      let pages = []
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart: function () {
      let start = this.page - this.pageRange
      return (start > 0) ? start : 1
    },
    rangeEnd: function () {
      let end = this.page + this.pageRange
      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages: function () {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage: function () {
      return this.page + 1
    },
    prevPage: function () {
      return this.page - 1
    }
  },
};
</script>

<style scoped lang="scss">
.page-item {
  .page-link {
    cursor: pointer;
  }

  &.active,
  &.disabled {
    .page-link {
      cursor: default;
    }
  }
}
</style>
