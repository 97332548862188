<template>
  <base-button class="btn-sm" v-if="user.status === 'disabled'" type="success" @click="toggleBan()">Разбанить пользователя</base-button>
  <base-button class="btn-sm" v-if="user.status === 'enabled'" type="danger" @click="toggleBan()">Забанить пользователя</base-button>
</template>

<script>
import { api } from "@/plugins/axios";

export default {
  name: 'user/ban',

  props: {
    user: {
      type: Object,
      default: () => {},
    }
  },

  emits: ['set-status', 'refresh-table'],

  methods: {
    async toggleBan() {
      try {
        this.loading = true;

        await api.post(`photographers/groups/${this.$route.params.itemId}/users/${this.user.id}/access/toggle`);
        this.user.status = this.user.status === 'enabled' ? 'disabled' : 'enabled';

        this.$emit('set-status', this.user.status);
        this.$emit('refresh-table');
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
