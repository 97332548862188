export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
        form: false,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'group_name',
        title: 'Группа',
        table: true,
        type: 'text',
    },
    {
        name: 'user_name',
        title: 'Пользователь',
        table: true,
        type: 'text',
    },
    {
        name: 'url',
        title: 'URL',
        type: 'text',
    },
    {
        name: 'status',
        title: 'Статус',
        render: true,
        sortable: true,
        type: 'select',
        show_name: 'status_name',
        options: 'statuses',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        form: false,
        table: true,
    },
];
