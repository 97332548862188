<template>
  <div class="card shadow" v-if="items.length">
    <div class="card-header">
      {{ title }}
      <br v-if="type === 'groups'" />
      <span class="hint text-danger" v-if="type === 'groups'">
        Блокировка загрузки фото в группу по прошествии 2х дней после ее создвния
      </span>
    </div>
    <div class="card-body">
      <div class="row">
        <div v-for="item in items" class="col-md-6 col-lg-3 text-center p-3 pointer" @click="buy(item)">
          <h3>{{ item.name }}</h3>
          <hr />
          {{ item.price }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/plugins/axios';

export default {
  name: 'additional/packages',

  props: {
    items: {
      type: Object,
      default: () => [],
    },

    title: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: '',
    },
  },

  methods: {
    async buy(item) {
      this.$parent.loading = true;
      try {
        await api.post(`photographers/packages/${item.id}/buy`);
      } catch (e) {
        throw e;
      } finally {
        this.$parent.loading = false;
      }
    }
  }
};
</script>
