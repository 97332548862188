<template>
  <nav
    class="navbar"
    :class="[
      { 'navbar-expand-md': expand },
      { 'navbar-transparent': transparent },
      { [`bg-${type}`]: type },
    ]"
  >
    <div :class="containerClasses">
      <slot name="brand">
        <span v-for="breadcrumb in breadcrumbs">
          <router-link
            :to="{ name: breadcrumb.name, params: $route.params }"
            class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          >
            {{ breadcrumb.title }}
          </router-link>
          &nbsp;/&nbsp;
          <span v-if="!!main && breadcrumb.breadcrumb_type === 'main'">
            <router-link
                :to="{ name: `${breadcrumb.name}/show`, params: $route.params }"
                class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            >
              {{ main.name ?? main.size }}
            </router-link>
            &nbsp;/&nbsp;
          </span>
          <span v-if="!!secondary && breadcrumb.breadcrumb_type === 'secondary'">
            <router-link
                :to="{ name: `${breadcrumb.name}/show`, params: $route.params }"
                class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            >
              {{ secondary.name ?? secondary.size }}
            </router-link>
            &nbsp;/&nbsp;
          </span>
          <span v-if="!!thirdly && breadcrumb.breadcrumb_type === 'thirdly'">
            <router-link
                :to="{ name: `${breadcrumb.name}/show`, params: $route.params }"
                class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            >
              {{ thirdly.name }}
            </router-link>
            &nbsp;/&nbsp;
          </span>
        </span>
        <span>{{ pageTitle }}</span>
      </slot>
      <navbar-toggle-button
        v-if="showToggleButton"
        :toggled="toggled"
        :target="contentId"
        @click.stop="toggled = !toggled"
      >
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <div
        class="collapse navbar-collapse"
        :class="{ show: toggled }"
        :id="contentId"
        v-click-outside="closeMenu"
      >
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "base-nav",
  components: {
    NavbarToggleButton,
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
        "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggled: false,
      route: null,
    };
  },
  computed: {
    ...mapGetters({ main: 'getMainBreadcrumb', secondary: 'getSecondaryBreadcrumb', thirdly: 'getThirdlyBreadcrumb' }),
    item() {
      return this.getRoute(this.$route.name);
    },
    breadcrumbs() {
      return this.$route.matched[0].children.filter(i => this.prepareField('links').includes(i.name));
    },
    pageTitle() {
      return this.prepareField('title');
    },
  },
  methods: {
    closeMenu() {
      this.toggled = false;
    },
    getRoute(name) {
      return this.$route.matched[0].children.filter(i => i.name === name)[0];
    },
    prepareField(name) {
      return this.getRoute(this.$route.name)[name] ?? '';
    },
  },
};
</script>
<style></style>
