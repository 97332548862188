<template>
  <base-button v-if="!!userId" class="btn-sm" type="secondary" @click="deleteModal = true;">Удалить пользователя</base-button>

  <action-modal title="Удаление"
                text="Вы действительно хотите удалить этого пользователя из данной галереи?"
                action-text="Удалить"
                :show-modal="deleteModal"
                item="deleteUserModal"
                v-on:action="deleteUser"
                :is-loading="loading"
                v-if="!!userId"
  />
</template>

<script>
import ActionModal from "@/views/modals/action-modal";

import { api } from "@/plugins/axios";
import { mapActions } from "vuex";

export default {
  name: 'user/delete',

  components: { ActionModal },

  emits: ['clear-user'],

  props: {
    userId: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      deleteModal: false,
      loading: false,
    };
  },

  methods: {
    ...mapActions(['setAppItem']),

    async deleteUser() {
      try {
        this.loading = true;
        this.setAppItem({ key: 'isLoading', value: true });

        await api.delete(`photographers/groups/${this.$route.params.itemId}/users/${this.userId}`).then(() => {
          this.deleteModal = false;

          this.$emit('clear-user');
        });
      } finally {
        this.loading = false;
        this.setAppItem({ key: 'isLoading', value: false });
      }
    },
  },
};
</script>
