<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="!!photographer" class="card shadow">
            <div class="card-body">
              <div v-for="field in fields">
                <div>
                  <base-input v-if="field.type === 'text'" alternative disabled :label="field.title" :value="photographer[field.name]"></base-input>
                  <base-input v-if="field.type === 'select'" alternative disabled :label="field.title" :value="photographer[field.show_name]"></base-input>
                  <base-checkbox v-if="field.type === 'checkbox'" disabled :checked="photographer[field.name] === 1" v-model="photographer[field.name]">{{ field.title }}</base-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'admin/photographers/show',
  computed: {
    ...mapGetters({
      photographer: 'adminPhotographer/getPhotographer'
    }),
  },
  data() {
    return {
      fields: require('@/columns/users').default,
    };
  },
  async created() {
    await this.fetchPhotographer(this.$route.params.photographerId);

    this.setAppItem({ key: 'mainBreadcrumb', value: this.photographer });
  },
  methods: {
    ...mapActions('adminPhotographer', ['fetchPhotographer']),
    ...mapActions(['setAppItem']),
  }
}
</script>
