<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Твой выбор"
      title="Твой выбор"
    >
      <template v-if="isAdmin" v-slot:links>
        <sidebar-item
            :link="{
            name: 'Главная',
            icon: 'ni ni-tv-2 text-primary',
            path: '/admin',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Фотографы',
            icon: 'ni ni-circle-08 text-primary',
            path: '/admin/photographers',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Пользователи',
            icon: 'ni ni-single-02 text-primary',
            path: '/admin/users',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Пакеты',
            icon: 'ni ni-bag-17 text-primary',
            path: '/admin/packages',
          }"
        />
      </template>
      <template v-if="isPhotographer" v-slot:links>
        <sidebar-item
            :link="{
            name: 'Главная',
            icon: 'ni ni-tv-2 text-primary',
            path: '/photographers',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Книги',
            icon: 'fa fa-book text-primary',
            path: '/photographers/books',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Календари',
            icon: 'fa fa-calendar text-primary',
            path: '/photographers/calendars',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Размеры',
            icon: 'fa fa-expand text-primary',
            path: '/photographers/sizes',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Магниты',
            icon: 'fa fa-magnet text-primary',
            path: '/photographers/magnets',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Водяные знаки',
            icon: 'fa fa-water text-primary',
            path: '/photographers/watermarks',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Обработка',
            icon: 'ni ni-badge text-primary',
            path: '/photographers/processings',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Группы',
            icon: 'fa fa-users text-primary',
            path: '/photographers/groups',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Заказы',
            icon: 'ni ni-cart text-primary',
            path: '/photographers/orders',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Скидки',
            icon: 'fa fa-percent text-primary',
            path: '/photographers/discounts',
          }"
        />
        <sidebar-item
            :link="{
            name: 'Приобрести пакет услуг',
            icon: 'ni ni-bag-17 text-primary',
            path: '/photographers/packages',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import DashboardNavbar from "./DashboardNavbar.vue";

export default {
  components: {
    DashboardNavbar,
  },
  computed: {
    ...mapGetters([
        'isAdmin',
        'isPhotographer',
    ])
  },
  data() {
    return {
      sidebarBackground: "vue",
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
