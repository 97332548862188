<template>
  <div>
    <loading v-if="loading" />
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow">
            <div class="card-header">{{ userName }}</div>
            <div class="card-body">
              <base-input form-classes="media-body mr-2" alternative label="ФИО" placeholder="Ваше ФИО" :value="userName" />
              <base-input form-classes="media-body mr-2" alternative label="Email" placeholder="Ваш e-mail" :value="userEmail" />
              <base-input form-classes="media-body mr-2" alternative label="Телефон" placeholder="Номер вашего телефона" :value="userPhone" />
              <base-input form-classes="media-body mr-2" alternative label="Логин" disabled placeholder="Ваше ФИО" :value="userLogin" />
              <base-input form-classes="media-body mr-2" alternative label="Пароль" placeholder="Ваш пароль" :value="userSecret">
                <template v-slot:infoBlock>
                  <span class="hint text-danger">Ваш пароль виден только вам и администратору</span>
                </template>
              </base-input>
              <div>
                <base-input label="Аватар" type="file" @change="changeAvatar" accept="image/*" />
                <div v-if="avatar" class="item-images">
                  <div class="item-image">
                    <img :src="avatar" :alt="userName" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import BaseInput from "@/components/BaseInput";
import ImageComponent from "@/views/components/forms/image";
import Loading from '@/components/Loading';

import { api } from "@/plugins/axios";

export default {
  name: 'profile',
  components: { BaseInput, ImageComponent, Loading },
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      userName: 'getUserName',
      userEmail: 'getUserEmail',
      userPhone: 'getUserPhone',
      userLogin: 'getUserLogin',
      userSecret: 'getUserSecret',
      avatar: 'getUserAvatar',
    }),
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapActions(['getUserData']),

    async changeAvatar(ev) {
      if (ev.target.files.length === 0) {
        return;
      }

     this.loading = true;
      try {
        let data = new FormData();
        data.append('avatar', ev.target.files[0]);

        await api.post('user/avatar', data).then(async () => {
          await this.getUserData();
        });
      } finally {
        ev.target.value = null;
        this.loading = false;
      }
    },
  },
};
</script>
