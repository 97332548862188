<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-lg-12">
          <div class="card shadow">
            <div class="card-body">
              <div v-for="field in fields">
                <base-input
                    v-if="field.type === 'text'"
                    alternative
                    :label="field.title"
                    v-model="item[field.name]"
                    :error="errors[field.name] ? errors[field.name][0] : null"
                />
                <base-select
                    v-if="field.type === 'select'"
                    alternative
                    :label="field.title"
                    :options="this[field.options]"
                    v-model="item[field.name]"
                    :error="errors[field.name] ? errors[field.name][0] : null"
                />
              </div>
              <div class="display-flex justify-content-end">
                <base-button type="success" @click="sendForm()">Создать</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { api } from '@/plugins/axios';

export default {
  name: 'admin/packages/store',
  computed: {
    ...mapGetters({ errors: 'getErrors' }),
  },
  data() {
    return {
      item: {},
      fields: require('@/columns/packages').default,
      types: [{ text: 'Период', value: 'days' }, { text: 'Группы', value: 'groups' }],
      statuses: [{ text: 'Включено', value: 'enabled' }, { text: 'Выключено', value: 'disabled' }],
    };
  },
  methods: {
    async sendForm() {
      this.isLoading = true;
      try {
        await api.post(`admin/packages`, this.item).then(() => {
          this.$router.push({ name: 'admin/packages' });
        });
      } catch (e) {
        this.isLoading = false;
        throw e;
      }
    },
  }
}
</script>
