export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
        form: false,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        form: false,
        table: true,
    },
    {
        name: 'updated_at',
        title: 'Дата последнего обновления',
        form: false,
        table: true,
    },
];
