import { createStore } from 'vuex';
import app from './modules/app';
import auth from './modules/auth';
import adminPhotographer from './modules/admin/photographer';
import adminUser from './modules/admin/user';
import photographer from './modules/photographer';
import adminPackage from './modules/admin/package';

export default createStore({
    modules: {
        app,
        auth,
        adminPhotographer,
        adminUser,
        photographer,
        adminPackage
    },
});
