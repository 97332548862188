<template>
  <div class="col-xs-12 col-lg-4 opacity">
    <div class="card shadow item-block position-relative overflow-hidden">
      <div>
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                <slot name="title"></slot>
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <slot name="data"></slot>
        </div>
        <div v-if="showFooter" class="card-footer">
          <slot name="footer"></slot>
        </div>
      </div>
      <div v-if="isAdditionalBlockLoading">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isAdditionalBlockLoading: this.isLoading,
    };
  },
  watch: {
    isLoading: function (value) {
      this.isAdditionalBlockLoading = value;
    }
  },
};
</script>

<style scoped>
.item-block {
  min-height: 400px;
}
</style>
