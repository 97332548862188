<template>
  <items-form :url="`photographers/${this.schema.type}`"
              :redirect-route="`photographers/${this.schema.type}`"
              :type="schema.type"
              :fields="fields"
              :item="getItem()"
              button-text="Создать"
              method="post"
              action="store"
  />
</template>

<script>
import ItemsForm from '@/views/components/forms/items';

import { mapGetters, mapActions } from "vuex";

export default {
  name: 'photographers/items/show',
  components: { ItemsForm },
  computed: {
    ...mapGetters({ id: 'getUserId' }),
    schema() {
      return require('@/types/photographer').default.filter(i => i.routes.includes(this.$route.name))[0];
    },
    fields() {
      return require(`@/columns/${this.schema.type}`).default.filter(i => i.form === undefined);
    },
  },
  methods: {
    getItem() {
      let item = {};
      for (let field of this.fields) {
        item[field.name] = field.type === 'checkbox' ? false : '';
      }
      return item;
    },
  }
};
</script>
