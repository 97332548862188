import axios from 'axios';
import store from "@/stores";
import router from "@/routes";

export const baseURL = 'https://api.foton.lamantin.spb.ru';

export const api = axios.create({
    baseURL
});

api.interceptors.response.use(null, (error) => {
    if (error.response.status === 401) {
        store.dispatch('logout');
        router.push({ name: 'Login' });
    }

    return Promise.reject(error);
});

