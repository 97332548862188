<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="transition-all" :class="{ 'col-lg-12': getFull(), 'col-lg-8': getShort() }">
          <div v-if="!!model" class="card shadow mh-500">
            <loader v-if="loading" />
            <div class="card-body">
              <base-input :disabled="disabled" :error="errors.name ? errors.name[0] : null" alternative label="Наименование" v-model="model.name" />
              <base-input :disabled="disabled" :error="errors.phone ? errors.phone[0] : null" alternative label="Телефон" v-model="model.phone" />
              <base-input :disabled="disabled" :error="errors.email ? errors.email[0] : null" alternative label="E-mail" v-model="model.email" />
              <base-input :disabled="disabled" :error="errors.date ? errors.date[0] : null" type="date" alternative label="Дата проведения фотосессии" v-model="model.date" />
              <base-input v-if="disabled" :disabled="disabled" alternative label="Логин" v-model="model.login" />
              <base-input v-if="disabled" :disabled="disabled" alternative label="Пароль" v-model="model.secret" />
              <base-button v-if="action !== 'store'" type="secondary" @click="open('showUsers'); $refs['users'].getUsers()">Показать пользователей всех галерей</base-button>
              <br />
              <br />
              <image-component v-if="action !== 'store'"
                     :show-input="!disabled"
                     :load-image="this.action !== 'store'"
                     :url="`photographers/groups/${this.$route.params.itemId}/image`"
              />
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="display-flex flex-wrap col-lg-6 col-xs-12 z-index-1 position-relative justify-content-start"
                     v-if="action !== 'store'"
                >
                  <div class="btn btn-secondary btn-sm display-flex align-items-center mb-1"
                       v-for="relation in relations"
                       @click="open('additional'); $refs['items'].showRelationBlock(relation)"
                  >
                    {{ relation.name }}
                  </div>
                </div>
                <div v-if="action !== 'show'"
                     class="display-flex justify-content-end col-xs-12 position-relative"
                     :class="{ 'col-lg-6': action !== 'store', 'col-lg-12': action === 'store' }"
                >
                  <base-button v-if="action === 'edit'" type="danger" @click="deleteModal = true">
                    Удалить фото всех групп
                  </base-button>
                  <base-button type="success" @click="sendForm()">{{ buttonText }}</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <action-modal title="Удаление"
                      text="Вы действительно хотите удалить фотографии всех галерей данной группы?"
                      action-text="Удалить"
                      :show-modal="deleteModal"
                      item="deleteModal"
                      v-on:action="deletePhotos"
                      :is-loading="modalLoading"
        />

        <items :additional="additional"
               ref="items"
               :disabled="disabled" :url="`photographers/groups/${$route.params.itemId}`"
        />

        <users ref="users" :action="action" :additional="showUsers" />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/axios";
import { mapActions, mapGetters } from "vuex";

import loader from "@/components/Loading";
import AdditionalBlock from "@/views/components/additional-block";
import ImageComponent from "@/views/components/forms/image";
import ActionModal from "@/views/modals/action-modal";
import Items from "@/views/photographer/additional/items";
import Users from '@/views/photographer/additional/users';

export default {
  name: 'forms/groups',
  components: { AdditionalBlock, loader, ImageComponent, ActionModal, Items, Users },
  props: {
    item: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    redirectRoute: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Редактировать',
    },
    method: {
      type: String,
      default: 'put',
    },
    type: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({ loading: 'getIsLoading', errors: 'getErrors' }),
  },
  data() {
    return {
      model: null,
      additional: false,
      deleteModal: false,
      modalLoading: false,
      showUsers: false,
      relations: require(`@/types/photographer/groups`).default,
    };
  },
  async created() {
    this.model = this.item;
  },
  unmounted() {
    this.setAppItem({ key: 'errors', value: {} });
  },
  methods: {
    ...mapActions(['setAppItem']),

    async deletePhotos() {
      this.modalLoading = true;
      try {
        await api.delete(`photographers/groups/${this.$route.params.itemId}/remove-photos`);
      } finally {
        this.deleteModal = false;
        this.modalLoading = false;
      }
    },

    async sendForm() {
      this.setAppItem({ key: 'isLoading', value: true });
      try {
        let url = `${this.url}${this.item.id ? ('/' + this.item.id) : ''}`;
        await api[this.method](url, this.model).then(({ data }) => {
          let id = null;
          if (data.id) {
            id = this.item.id;
          } else {
            id = data.id;
          }
          let params = this.$route.params;
          params.itemId = id;
          this.$router.push({ name: this.redirectRoute, params });
        });
      } catch (e) {
        throw e;
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }
    },

    getFull() {
      return !this.additional && !this.showUsers;
    },

    getShort() {
      return this.additional || this.showUsers;
    },

    open(item) {
      ['additional', 'showUsers'].map(i => this[i] = item === i);
    },
  },
  watch: {
    item(data) {
      this.model = data;
    }
  }
};
</script>
