export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
        form: false,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'size',
        title: 'Размер',
        table: true,
        type: 'text',
    },
    {
        name: 'price',
        title: 'Цена',
        table: true,
        type: 'text',
    },
    {
        name: 'on_page_count',
        title: 'Кол-во фото на развороте',
        type: 'text',
    },
    {
        name: 'min_in_book',
        title: 'Мин. кол-во фото в книге',
        type: 'text',
        hint: 'Кол-во меньше которого нельзя выбрать в книгу',
    },
    {
        name: 'max_in_book',
        title: 'Макс. кол-во фото в книге',
        type: 'text',
        hint: 'Кол-во фото стоимость за которые не снимается',
    },
    {
        name: 'price_on_page',
        title: 'Цена за доп. разворот',
        table: true,
        type: 'text',
        hint: 'Цена за каждый разворот после перехода попрога максимального кол-ва в книге',
    },
    {
        name: 'on_head_count',
        title: 'Кол-во фото на обложке',
        type: 'text',
    },
    {
        name: 'orientation',
        title: 'Ориентация',
        table: true,
        type: 'select',
        render: true,
        show_name: 'orientation_name',
        options: 'orientations',
    },
    {
        name: 'finish_page',
        title: 'Есть ли винетка',
        type: 'checkbox',
    },
    {
        name: 'use_constructor',
        title: 'Разрешить использовать конструктор',
        type: 'checkbox',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        form: false,
        table: true,
    },
    {
        name: 'updated_at',
        title: 'Дата последнего обновления',
        form: false,
        table: true,
    },
];
