<template>
  <additional-block v-if="additional" :is-loading="loading" :show-footer="!disabled">
    <template v-slot:title>
      <div class="display-flex justify-content-between">
        <span>{{ user.name }}</span>
        <base-button @click="$parent.showUser = false; user = null;" type="primary" size="sm">Закрыть</base-button>
      </div>
    </template>
    <template v-if="user" v-slot:data>
      <base-input v-for="field in fields"
                  alternative
                  :label="field.title"
                  disabled="disabled"
                  :value="field.type === 'text' ? user[field.name] : user[field.show_name]"
      />
    </template>
    <template v-if="!disabled" v-slot:footer>
      <ban :user="user" v-on:refresh-table="refreshTable" v-on:set-status="setStatus" />

      <slot name="additional_actions" />
    </template>
  </additional-block>
</template>

<script>
import AdditionalBlock from "@/views/components/additional-block";
import Ban from '@/views/components/user/ban';

export default {
  name: 'additional/user',

  components: { AdditionalBlock, Ban },

  props: {
    user: {
      type: Object,
      default: () => {},
    },

    additional: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fields() {
      return require(`@/columns/users`).default.filter(i => i.form === undefined);
    },
  },

  data() {
    return {
      loading: false,
      statuses: [{ text: 'Активный', value: 'enabled' }, { text: 'Заблокирован', value: 'disabled' }],
    };
  },

  methods: {
    setStatus(status) {
      this.user.status_name = this.statuses.filter(i => i.value === status)[0].text;
    },

    refreshTable() {
      this.$emit('refresh-table');
    }
  },
};
</script>
