<template>
  <div
    class="form-group"
    :class="[
      { 'has-danger': error },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
      formClasses,
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>
    <slot>
      <select class="form-select form-control"
              @change="updateValue"
              v-bind="$attrs"
              :value="modelValue"
              :class="[
                { 'is-valid': valid === true },
                { 'is-invalid': valid === false },
                inputClasses,
              ]"
      >
        <option value="">Ничего не выбрано</option>
        <option v-for="option in options" :value="option.value">{{ option.text }}</option>
      </select>
    </slot>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
          class="text-danger invalid-feedback"
          style="display: block"
          v-if="error"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether select is required (adds an asterix *)",
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined,
    },
    label: {
      type: String,
      description: "Select label (text before input)",
    },
    modelValue: {
      type: [String, Number],
      description: "Input value",
    },
    error: {
      type: String,
      description: "Select error (below input)",
    },
    formClasses: {
      type: String,
      description: "Form css classes",
    },
    labelClasses: {
      type: String,
      description: "Select label css classes",
    },
    inputClasses: {
      type: String,
      description: "Select css classes",
    },
    options: {
      type: Array,
      default: () => [],
      description: "Form select",
    }
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("update:modelValue", value);
    },
  },
};
</script>
<style></style>
