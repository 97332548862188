import { api } from '@/plugins/axios';

const state = {
    package: null,
};

const getters = {
    getPackage: state => state.package,
};

const actions = {
    async fetchPackage({ commit }, packageId ) {
        try {
            const { data } = await api.get(`admin/packages/${packageId}`);
            commit('setItem', { key: 'package', value: data });
        } catch (e) {
            throw (e);
        }
    },
};

const mutations = {
    setItem(state, { key, value }) {
        state[key] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
