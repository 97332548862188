export default [
    {
        name: 'id',
        title: 'ID',
        sortable: true,
        width: '100px',
        table: true,
        form: false,
    },
    {
        name: 'name',
        title: 'Наименование',
        sortable: true,
        table: true,
        type: 'text',
    },
    {
        name: 'size',
        title: 'Размер',
        table: true,
        type: 'text',
    },
    {
        name: 'price',
        title: 'Цена',
        table: true,
        type: 'text',
    },
    {
        name: 'type',
        title: 'Тип',
        table: true,
        type: 'select',
        render: true,
        show_name: 'type_name',
        options: 'types',
    },
    {
        name: 'orientation',
        title: 'Ориентация',
        table: false,
        type: 'select',
        render: true,
        show_name: 'orientation_name',
        options: 'orientations',
    },
    {
        name: 'count',
        title: 'Кол-во фото',
        table: true,
        type: 'text',
    },
    {
        name: 'created_at',
        title: 'Дата создания',
        form: false,
        table: true,
    },
    {
        name: 'updated_at',
        title: 'Дата последнего обновления',
        form: false,
        table: true,
    },
];
