<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="transition-all" :class="{ 'col-lg-8': isShowBlock, 'col-lg-12': !isShowBlock }">
          <div class="card shadow">
            <div class="card-body" v-if="!image">
              <span class="alert alert-danger w-100 d-block">
                Картинка не должна превышать 500 килобайт.
              </span>
              <div class="file-uploader">
                <input type="file"
                       @change="uploadImage"
                       accept="image/*"
                />
                <span class="center">Нажмите на этот блок и выберите файлы или перетащите файлы в этот блок.</span>
              </div>
            </div>
            <div class="card-footer item-images">
              <loader v-if="loading" />
              <div v-if="image" class="item-images">
                <div class="item-image">
                  <img :src="image.url" />
                </div>
                <div class="mt-3">
                  <div class="btn btn-danger" @click="deleteImage">Удалить</div>
                  <br />
                  <div v-if="item.type === 'many'" class="btn btn-primary mt-2" @click="isShowBlock = true">Настроить позиции</div>
                </div>
              </div>
              <div v-else class="alert alert-info w-100 d-block text-center">{{ text }}</div>
            </div>
          </div>
        </div>
        <additional-block v-if="isShowBlock" :is-loading="blockLoading">
          <template v-if="!!image" v-slot:title>
            <div class="display-flex justify-content-between">
              <span>Настройка позиций</span>
              <base-button @click="isShowBlock = false" type="primary" size="sm">Закрыть</base-button>
            </div>
          </template>
          <template v-if="!!image" v-slot:data>
            <span class="text-danger mb-2 font-italic d-block font-size-12">
              Нажмите "разблокировать", чтобы перейти в режим фиксации фото на изображении календаря.
              <br />
              Выделите область фото на изображении и нажмите зафиксировать.
              <br />
              После того как зафиксируете все фото на изображении календаря, нажмите сохранить.
            </span>
            <cropper :image="image.original_url.replace('storage', 'static')" :data="image.positions" v-on:save="saveData" />
          </template>
        </additional-block>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/plugins/axios';
import { mapActions, mapGetters } from "vuex";

import loader from '@/components/Loading';
import additionalBlock from "@/views/components/additional-block";
import cropper from "@/components/Cropper";

export default {
  name: 'photographers/books/images',
  components: { loader, additionalBlock, cropper },
  computed: {
    ...mapGetters({ loading: 'getIsLoading' }),
    ...mapGetters({ item: 'photographer/getCalendar' }),
  },
  data() {
    return {
      image: null,
      text: null,
      isShowBlock: false,
      positions: [],
      blockLoading: false,
    };
  },
  unmounted() {
    this.setAppItem({ key: 'mainBreadcrumb', value: null });
  },
  async created() {
    await this.getItem();

    this.setAppItem({ key: 'mainBreadcrumb', value: this.item })

    await this.getImage();

  },
  methods: {
    ...mapActions(['setAppItem']),
    ...mapActions('photographer', ['fetchPhotographerItem']),
    async getItem() {
      await this.fetchPhotographerItem({
        itemId: this.$route.params.itemId,
        key: 'calendar',
        type: 'calendars',
      });
    },
    async getImage() {
      this.text = 'Подгрузка изображения';

      this.setAppItem({ key: 'isLoading', value: true });
      try {
        const { data } = await api.get(`photographers/calendars/${this.$route.params.itemId}/image`);

        if (data.conversions) {
          let conversions = data.conversions;
          if (conversions.length) {
            conversions = conversions.filter(i => i.name === 'image');
            if (conversions.length) {
              this.image = {
                id: data.id,
                name: data.name,
                url: conversions[0].url,
                original_url: data.url,
                positions: this.item.data,
              };
            }
          }
        }
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }

      this.text = 'Изображение не загружено';
    },
    async uploadImage(ev) {
      if (ev.target.files.length === 0) {
        return;
      }
      this.setAppItem({ key: 'isLoading', value: true });
      try {
        let data = new FormData();
        data.append('image', ev.target.files[0]);

        await api.post(`photographers/calendars/${this.$route.params.itemId}/image`, data).then(async () => {
          await this.getImage();
        });
      } catch (e) {
        ev.target.value = null;
      } finally {
        this.setAppItem({ key: 'isLoading', value: false });
      }
    },
    async deleteImage() {
      await api.delete(`photographers/calendars/${this.$route.params.itemId}/image`).then(() => {
        this.image = null;
      });
    },
    async saveData(positions) {
      this.blockLoading = true;
      try {
        await api.post(`photographers/calendars/${this.$route.params.itemId}/image/set-data`, { data: positions }).then(() => {
          this.image.positions = positions;
        });
      } finally {
        this.blockLoading = false;
        this.isShowBlock = false;
      }
    },
  }
}
</script>
