import { api } from '@/plugins/axios';

const state = {
    user: null,
    item: null
};

const getters = {
    getUser: state => state.user,
    getItem: state => state.item,
};

const actions = {
    async fetchUser({ commit }, userId ) {
        try {
            const {data} = await api.get(`admin/users/${userId}`);
            commit('setItem', { key: 'user', value: data });
        } catch (e) {
            throw (e);
        }
    },

    fetchItemSecret({ commit }, { key, value } ) {
        let item = state[key];
        item.secret = value;

        commit('setItem', { key, value: item });
    }
};

const mutations = {
    setItem(state, { key, value }) {
        state[key] = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
