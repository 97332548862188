<template>
  <div id="image-block" class="mb-2">
    <span v-if="!started && positions.length"
          v-for="(position, index) in positions"
          :key="index"
          class="selected completed"
          :style="{
            top: `${position.topSmall}px`,
            left: `${position.leftSmall}px`,
            width: `${position.widthSmall}px`,
            height: `${position.heightSmall}px`
          }"
    >{{ index + 1 }}</span>
    <img id="imageCropTemplate" @load="rewritePositions" :src="image" alt="" :class="{ 'd-none': started }" />
    <cropper
        v-if="started"
        ref="cropper"
        class="cropper"
        :src="image"
        @change="setPosition"
    />
    <div class="col-lg-12 display-flex justify-content-end mt-3">
      <div v-if="!started" @click="reCrop" class="btn btn-danger">Разблокировать</div>
      <div v-if="started" @click="savePosition" class="btn btn-warning">Зафиксировать</div>
      <br />
      <div v-if="started" @click="savePositions()" class="btn btn-success">Сохранить</div>
    </div>
    <div v-if="started" v-for="(item, index) in positions" :key="index" class="display-flex justify-content-between mt-3">
      <img class="mini-image" :src="item.image" alt="" />
      <div class="display-flex align-items-center">
        <span class="btn btn-danger" @click="deletePosition(index)"><i class="fa fa-trash" /></span>
      </div>
    </div>
    <canvas id="cropImageCanvas" class="d-none"></canvas>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'image-cropper',
  components: { Cropper },
  props: {
    image: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      positions: [],
      started: false,
      base64: null,
      position: null,
      loaded: false,
    };
  },
  methods: {
    setPosition({ coordinates, canvas }) {
      this.loaded = true;
      this.position = { ...coordinates, image: canvas.toDataURL() };
    },
    async savePosition() {
      let imgBlock = document.getElementsByClassName('vue-advanced-cropper__background')[0];
      let img = document.getElementsByClassName('vue-advanced-cropper__image')[0];
      let percentX = (imgBlock.offsetWidth * 100) / img.naturalWidth;
      let percentY = (imgBlock.offsetHeight * 100) / img.naturalHeight;

      this.positions.push(this.getAdditionalData(this.position, percentX, percentY));
      this.position = null;
    },
    savePositions() {
      this.started = false;

      this.$emit('save', this.positions.map((i, k) => {
        return {
          top: i.top,
          left: i.left,
          width: i.width,
          height: i.height,
          orientation: i.orientation,
          item: k + 1
        };
      }));
    },
    rewritePositions() {
      let array = this.data;
      let img = document.getElementById('imageCropTemplate');
      let percentX = (img.offsetWidth * 100) / img.naturalWidth;
      let percentY = (img.offsetHeight * 100) / img.naturalHeight;

      this.positions = array.map((i) => {
        return this.getAdditionalData(i, percentX, percentY);
      });
    },
    getAdditionalData(item, percentX, percentY) {
      return {
        ...item,
        ...{
          topSmall: (item.top / 100) * percentY,
          leftSmall: (item.left / 100) * percentX,
          widthSmall: (item.width / 100) * percentX,
          heightSmall: (item.height / 100) * percentY,
          orientation: item.width > item.height ? 'horizontal' : 'vertical',
        }
      };
    },
    async reCrop() {
      this.started = true;
      this.$parent.isAdditionalBlockLoading = true;
      if (!this.loaded) {
        await this.$sleep(500);
        return await this.reCrop();
      }

      for (let i = 0; i < this.positions.length; i++) {
        if (!this.positions[i].image) {
          this.position = null;
          this.$refs.cropper.setCoordinates(this.positions[i]);
          await this.saveImage(i);
        }
      }
      this.$refs.cropper.reset();
      this.$parent.isAdditionalBlockLoading = false;
    },
    async saveImage(i) {
      if (!this.position) {
        await this.$sleep(500);
        return await this.saveImage(i);
      }

      this.positions[i].image = this.position.image;
      this.position = null;
    },
    deletePosition(index) {
      this.positions.splice(index, 1);
    },
  },
};
</script>
